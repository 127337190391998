import React from 'react';

const PageHeadingContent = (props) => {
    return (
        <div>
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <div id="first-section-margin-top-for-navbar">
                                <h1 className="h1-tag-lm">{props.heading}</h1>
                            </div>

                            <div>
                                <h2 className="h2-subtitle-lm">
                                    {props.subtitle}
                                </h2>
                            </div>

                            <div>
                                <p className="p-content-lm">
                                    {props.content}
                                </p>
                            </div>

                            <div>
                                <p className="p-content-lm">
                                    {props.contentTwo}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageHeadingContent;