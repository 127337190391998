import React from 'react';

const EnableExternalSharing = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 50 50" fill="gray" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.0002 12.5002H12.5002C11.3951 12.5002 10.3353 12.9391 9.55388 13.7206C8.77248 14.502 8.3335 15.5618 8.3335 16.6668V37.5002C8.3335 38.6052 8.77248 39.665 9.55388 40.4464C10.3353 41.2278 11.3951 41.6668 12.5002 41.6668H33.3335C34.4386 41.6668 35.4984 41.2278 36.2798 40.4464C37.0612 39.665 37.5002 38.6052 37.5002 37.5002V25.0002M22.9168 27.0835L41.6668 8.3335M41.6668 8.3335H31.2502M41.6668 8.3335V18.7502" stroke="gray" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
};

export default EnableExternalSharing;