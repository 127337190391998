import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ApiDataContext } from '../../context/ApiDataContext';
import useWindowSize from '../commonComponents/commonFunction/UseWindowSize';
import './Footer.css'

const Footer = () => {
    const { productPageData, theme } = useContext(ApiDataContext);
    const { windowWidth } = useWindowSize();
    let newDate = new Date().getFullYear();

    return (
        <div className="dark-mode-footer-subtract-bg light-mode-footer-body transparent-footer">
            <div className="container-fluid">
                <div className="container">
                    <div className="footer-list-container" >
                        <div className="row">
                            {windowWidth > 480 ?
                                <div className='row col col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                    <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 product-footer">
                                        <div className="footer-list">
                                            <ul>
                                                <h5>Products</h5>
                                                {productPageData?.slice(0, Math.ceil(productPageData.length / 2)).map((e) => {

                                                    let updatedTitle = e?.projectTitle

                                                    if (e.projectTitle?.includes('Management') && e.projectTitle?.includes('System')) {
                                                        updatedTitle = e.projectTitle.replace('System', '').trim();
                                                    }

                                                    return e.displayOrder === 0 ? null : (
                                                        <li key={e.routPath}><Link to={e?.routPath}>{updatedTitle}</Link></li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                                        <div className="footer-list">
                                            <ul>
                                                <br />
                                                {productPageData?.slice(Math.ceil(productPageData.length / 2))?.map((e) => {
                                                    let updatedTitle = e?.projectTitle

                                                    if (e.projectTitle?.includes('Management') && e.projectTitle?.includes('System')) {
                                                        updatedTitle = e.projectTitle.replace('System', '').trim();
                                                    }

                                                    return e.displayOrder === 0 ? null : (
                                                        <li key={e.routPath}><Link to={e?.routPath}>{updatedTitle}</Link></li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                    <div className="footer-list">
                                        <ul>
                                            <h5>Products</h5>
                                            {productPageData?.map((e) => {
                                                let updatedTitle = e?.projectTitle

                                                if (e.projectTitle?.includes('Management') && e.projectTitle?.includes('System')) {
                                                    updatedTitle = e.projectTitle.replace('System', '').trim();
                                                }

                                                return e.displayOrder === 0 ? null : (
                                                    <li key={e.routPath}><Link to={e?.routPath}>{updatedTitle}</Link></li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            }

                            <div className="col col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="footer-list">
                                    <ul>
                                        <h5>Company</h5>
                                        <li><Link to="/about-us">About us</Link></li>
                                        <li><Link to="/our-commitment">Our Commitment</Link></li>
                                        <li><Link to="/career-at-sharepointempower">Career at SharepointEmpower</Link></li>
                                        <li><Link to="/contact-us">Get in touch</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 hidden md:block">
                                <div className="footer-list">
                                    <ul>
                                        <h5>Resources</h5>
                                        <li><a href="https://blog.sharepointempower.com/" target="_blank" rel="noreferrer noopener" >Blog</a></li>
                                        <li><Link to="/faqs" >FAQs</Link></li>
                                        <li><Link to="/contact-us" >Get Quotation</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-logo-box">
                        <div className="row">
                            <div className="col col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 align-content-center mobile-footer-flex mobile-footer-padding">
                                <Link to="/">
                                    {theme == "dark-mode-body" ?
                                        <img className="footer-logo-img" src={require("../../assets/images/sharepointempowerLogo/spe-navbar-black.webp")} alt="SPE_logo_white" /> :
                                        <img className="footer-logo-img" src={require("../../assets/images/sharepointempowerLogo/spe-navbar-white.webp")} alt="SPE_logo_white" />}
                                </Link>
                                <img className="indian-flag" src={require("../../assets/images/icons/india.png")} alt="indian-flag" />
                                <div>
                                    <p className='mb-0 ml-2 text-xs mt-1 ' style={{ 'color': 'gray' }}>CIN: U72900GJ2021PTC120956</p>
                                </div>
                            </div>
                            <div className='row col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-content-center mobile-footer-padding'>
                                <div className="col col-5 col-sm-6 col-md-5 col-lg-5 col-xl-5 col-xxl-5 mobile-footer-padding">
                                    <div className="footer-logo-list">
                                        <span>Follow us</span>
                                        <ul>
                                            <li>
                                                <a href="https://in.linkedin.com/company/sharepointempower"
                                                    target="_blank" rel="noreferrer noopener"><i className=" pi pi-linkedin text-xl"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/sharepointempower_/?hl=en" target="_blank"
                                                    rel="noreferrer noopener"><i className=" pi pi-instagram text-xl"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com/@sharepointempower/videos" target="_blank"
                                                    rel="noreferrer noopener"><i className=" pi pi-youtube text-xl"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col col-7 col-sm-6 col-md-7 col-lg-7 col-xl-7 col-xxl-7 setimg mobile-footer-padding">
                                    <a href='https://www.sharepointempower.com/assets/docs/SharePointEmpower_Congratulatory_Letter.pdf' target='_blank'>
                                        <img className="footer-ms-img " src={require("../../assets/images/microsoftSolutionsPartner/microsoft-solution-partner.webp")} alt="SPE_logo_white" />
                                    </a>
                                </div>
                            </div>
                            <div className="col col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 align-content-center mobile-footer-padding">
                                <div className="footer-logo-list">
                                    <span>Contact us</span>
                                    <ul>
                                        <li>
                                            <i className="prime-icon pi pi-envelope"></i><a href="mailto:support@sharepointempower.com"> support@sharepointempower.com</a>
                                        </li>
                                        <li>
                                            <i className="prime-icon pi pi-user"></i><a href="mailto:career@sharepointempower.com" > career@sharepointempower.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={windowWidth > 768 ? 'flex justify-content-between mx-3' : ''}>
                        <div className="row">
                            <div
                                className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
                            >
                                <div className="footer-policy-list flex justify-content-center align-items-center">
                                    <ul id="copyright">
                                        <li>© {newDate} All Rights Reserved</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row footer-policy-list-anim">
                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div className="footer-policy-list flex justify-content-center align-items-center">
                                    <ul>
                                        <li><Link to="/privacy-policy">Privacy Policy |</Link> </li>
                                        <li><Link to="/legal-terms-of-use">Legal Terms of Use |</Link>  </li>
                                        <li><Link to="/site-map">Site Map</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default Footer;