import React, { useContext, useEffect } from 'react';
import PageHeadingContent from '../../commonComponents/pageHeadingContent/PageHeadingContent';
import ReactHelmetTag from '../../commonComponents/reactHelmet/ReactHelmetTag';
import { ApiDataContext } from '../../../context/ApiDataContext';
import './JobOpenings.css';

const JobOpenings = () => {
    const { jobOpeningData } = useContext(ApiDataContext);

    useEffect(() => {
        document.title = "Job openings";
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/job-openings"
                metaKeywords="job openings at sharepointempower, job opportunities, job opening"
            />
            <PageHeadingContent heading="Job Openings" content="Join our team and become part of a community that values collaboration, creativity, and innovation. We're committed to helping you grow in your career and achieve your full potential." />

            <div className="container-fluid">
                <div className="container">
                    {jobOpeningData.length > 0 ? (
                        <>
                            <p><b>- To apply for the job position, simply click the 'Apply' button and submit your resume to us. We'll be in touch with you shortly.</b></p>
                            {jobOpeningData?.map((job, index) => (
                                <div className='row' key={index}>
                                    <div className='col col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8 job-position'>
                                        <h4><b>{job.title}</b></h4>
                                        <p>{job.jobDescription}</p>
                                        <p className='font-semibold'><mark>Number of Positions : {job.jobPosition}</mark></p>
                                    </div>
                                    <div className='col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center md:justify-content-end job-apply-button'>
                                        <a className='m-3 mx-0 text-sm p-2 col-4 col-md-6 col-lg-3' href={`mailto:career@sharepointempower.com?subject=Applying for the Position of ${encodeURIComponent(job.title)} at SharePointEmpower`}>
                                            Apply<i className="pi pi-arrow-up-right px-1"></i>
                                        </a>
                                    </div>
                                    <hr />
                                </div>
                            ))}
                        </>
                    ) : (
                        <p>- For any inquiries regarding open positions at our company, please contact us at <a className='underline text-blue-500' href="mailto:career@sharepointempower.com">career@sharepointempower.com</a></p>
                    )}

                </div>
            </div>
        </div>
    );
};

export default JobOpenings;