import React from 'react';
import useWindowSize from '../commonFunction/UseWindowSize';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './Loading.css'

const Loading = () => {

    const { windowWidth } = useWindowSize();

    return (
        <div className="loader-container">
            {windowWidth > 480 && <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <LazyLoadImage
                    fetchpriority="high"
                    className='w-6'
                    loading='lazy'
                    src={require("../../../assets/images/sharepointempowerLogo/spe-navbar-white.webp")}
                    alt="Microsoft-solution-partner"
                    visibleByDefault={require("../../../assets/images/sharepointempowerLogo/spe-navbar-white.webp")}
                />
            </div>}
            {windowWidth > 480 ?
                <video id='banner-video' height="100%" width="100%" autoPlay loop muted playsInline>
                    <source src={require('../../../assets/videos/LandingPageVideo/landing-video-website.mp4')} type='video/mp4' />
                </video> :
                <div>
                    <img className='w-full' fetchpriority="high" src={require('../../../assets/images/sharepointempowerBackground/background-banner-mobile.webp')} alt="Splash Screen" />
                </div>
            }
            {windowWidth > 480 && <div style={{ position: 'absolute', bottom: 0, right: 10, width: '20%' }}>
                <div className='flex justify-content-end'>
                    <img
                        className="microsoft-icon w-7"
                        fetchpriority="high"
                        src={require("../../../assets/images/microsoftSolutionsPartner/microsoft-solution-partner-white.webp")}
                        alt="Microsoft-solution-partner"
                    />
                </div>
            </div>}
        </div >
    );
};

export default Loading;