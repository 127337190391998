import React from 'react';
import ReactHelmetTag from '../../commonComponents/reactHelmet/ReactHelmetTag';
import Allprojects from '../../commonComponents/userGuide/Allprojects';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const ASGuide = () => {
    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/guidelines/approval-system"
                metaKeywords="aprroval System, microsoft approval syatem, microsoft 365 approval system, m365 approval system, ticket generate "
            />
            <section>
                <LazyLoadComponent>
                    <Allprojects project="Approval System" path="/approval-system" />
                </LazyLoadComponent>
            </section >
        </div >
    )
};

export default ASGuide;