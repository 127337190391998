import React from "react";
import ReactHelmetTag from "../../commonComponents/reactHelmet/ReactHelmetTag";
import Allprojects from "../../commonComponents/userGuide/Allprojects";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function AuditGuide() {
  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/guidelines/audit-inspection"
        metaKeywords="Audit inspection guidelines, Audit inspection installation steps, Audit inspection help center, Sharepoint Audit inspection, Audit inspection steps"
      />
      <section>
        <LazyLoadComponent>
          <Allprojects project="Audit Inspection" path="/audit-inspection" ></Allprojects>
        </LazyLoadComponent>
      </section>
    </div>
  );
};

export default AuditGuide;
