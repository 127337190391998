import React, { useEffect } from 'react'
import PageHeadingContent from '../../commonComponents/pageHeadingContent/PageHeadingContent';
import ReactHelmetTag from '../../commonComponents/reactHelmet/ReactHelmetTag';
import Megamenu from '../../megamenu/Megamenu';

const FindUs = () => {
    useEffect(() => {
        document.title = "Find us";
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Megamenu />
                <ReactHelmetTag
                    link="https://www.sharepointempower.com/find-us"
                    metaKeywords="find us here"
                />
            <div className='mt-7'>
                <PageHeadingContent heading="Find us" />
                <div className="container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.1557175780554!2d70.77789041541519!3d22.27209044954546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959cbb42911224b%3A0x92adaf4e004e42e7!2sSharepointEmpower%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1677222768603!5m2!1sen!2sin&z=100"
                        allowfullscreen="" loading="lazy" className='w-full h-29rem'
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
        </>
    );
};

export default FindUs;