import React, { useContext } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from '../home/Index';
import OurCommitment from '../aboutUs/ourCommitment/OurCommitment';
import AssetManagementSystem from '../products/AssetManagementSystem';
import LearningManagementPortal from '../products/LearningManagementPortal';
import AuditInspection from '../products/AuditInspection';
import ConstructionManagementSystem from '../products/ConstructionManagementSystem';
import PrivacyPolicy from '../footer/PrivacyPolicy';
import LegalTermOfUse from '../footer/LegalTermOfUse';
import SiteMap from '../footer/SiteMap';
import AMSGuide from '../products/productGuidePages/AMSGuide';
import AuditGuide from '../products/productGuidePages/AuditGuide';
import CMSGuide from '../products/productGuidePages/CMSGuide';
import LMPGuide from '../products/productGuidePages/LMPGuide';
import ScrollToTop from '../commonComponents/scrollToTop/ScrollToTop';
import Helpdesk from '../products/Helpdesk';
import SPEIntranet from '../products/SPEIntranet';
import HelpdeskGuide from '../products/productGuidePages/HelpdeskGuide';
import SPEIntranetGuide from '../products/productGuidePages/SPEIntranetGuide';
import ProductPricingList from '../priceList/ProductPricingList';
import IMSGuide from '../products/productGuidePages/IMSGuide';
import InventoryManagementSystem from '../products/InventoryManagementSystem';
import Allproducts from '../products/allProducts/Allproducts';
import ApprovalSystem from '../products/ApprovalSystem';
import ASGuide from '../products/productGuidePages/ASGuide';
import FAQ from '../footer/FAQ';
import OrderStatus from '../commonComponents/paymentGateway/OrderStatus';
import ApiDataProvider, { ApiDataContext } from '../../context/ApiDataContext';
import CareerAtSharepointEmpower from '../aboutUs/careerAtSharepointEmpower/CareerAtSharepointEmpower';
import FindUs from '../aboutUs/findUs/FindUs';
import JobOpenings from '../aboutUs/jobOpenings/JobOpenings';
import WhoWeAre from '../aboutUs/whoWeAre/WhoWeAre';
import ClientsAcrossTheGlobe from '../commonComponents/clientsAcrossTheGlobe/ClientsAcrossTheGlobe';
import Footer from '../footer/Footer';
import Loading from '../commonComponents/loading/Loading';
import PaymentGateway from '../commonComponents/paymentGateway/PaymentGateway';
import ContactUs from '../commonComponents/contactUs/ContactUs';
import NotFound from './NotFound';

const MasterPage = () => {

    const { isLoading } = useContext(ApiDataContext);
    const location = useLocation();
    const excludedPaths = ['/faqs', '/privacy-policy', '/legal-terms-of-use', '/guidelines', '/order-status'];
    return (
        <ApiDataProvider>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Routes>
                        <Route path="*" element={<NotFound />} />
                        <Route exact path='/' element={<HomePage />} />
                        <Route exact path='/about-us' element={<WhoWeAre />} />
                        <Route exact path='/contact-us' element={<ContactUs />} />
                        <Route exact path='/our-commitment' element={<OurCommitment />} />
                        <Route exact path='/career-at-sharepointempower' element={<CareerAtSharepointEmpower />} />
                        <Route exact path='/order-page' element={<PaymentGateway />} />
                        <Route path='/order-status/:order_id' element={<OrderStatus />} />
                        <Route exact path='/find-us' element={<FindUs />} />
                        <Route exact path='/all-products' element={<Allproducts />} />
                        <Route exact path='/job-openings' element={<JobOpenings />} />
                        <Route exact path='/product-pricing-list' element={<ProductPricingList />} />
                        <Route exact path='/asset-management-system' element={<AssetManagementSystem />} />
                        <Route exact path='/learning-management-system' element={<LearningManagementPortal />} />
                        <Route exact path='/audit-inspection' element={<AuditInspection />} />
                        <Route exact path='/construction-management-system' element={<ConstructionManagementSystem />} />
                        <Route exact path='/helpdesk-system' element={<Helpdesk />} />
                        <Route exact path='/spe-intranet' element={<SPEIntranet />} />
                        <Route exact path='/inventory-management-system' element={<InventoryManagementSystem />} />
                        <Route exact path='/approval-system' element={<ApprovalSystem />} />
                        <Route exact path='/legal-terms-of-use' element={<LegalTermOfUse />} />
                        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route exact path='/site-map' element={<SiteMap />} />
                        <Route exact path='/faqs' element={<FAQ />} />

                        <Route exact path='/guidelines/asset-management-system' element={<AMSGuide />} />
                        <Route exact path='/guidelines/learning-management-system' element={<LMPGuide />} />
                        <Route exact path='/guidelines/audit-inspection' element={<AuditGuide />} />
                        <Route exact path='/guidelines/construction-management-system' element={<CMSGuide />} />
                        <Route exact path='/guidelines/helpdesk-system' element={<HelpdeskGuide />} />
                        <Route exact path='/guidelines/spe-intranet' element={<SPEIntranetGuide />} />
                        <Route exact path='/guidelines/inventory-management-system' element={<IMSGuide />} />
                        <Route exact path='/guidelines/approval-system' element={<ASGuide />} />

                        <Route path="/guidelines/learning-management-portal/:level1category" element={<LMPGuide />} />
                        <Route path="/guidelines/construction-management-system/:level1category" element={<CMSGuide />} />
                        <Route path="/guidelines/helpdesk/:level1category" element={<HelpdeskGuide />} />
                        <Route path="/guidelines/asset-management-system/:level1category" element={<AMSGuide />} />
                        <Route path="/guidelines/spe-intranet/:level1category" element={<SPEIntranetGuide />} />
                        <Route path="/guidelines/audit-inspection/:level1category" element={<AuditGuide />} />
                        <Route path="/guidelines/inventory-management-system/:level1category" element={<IMSGuide />} />
                        <Route path="/guidelines/approval-system/:level1category" element={<ASGuide />} />

                        <Route path="/guidelines/learning-management-portal/:level1category/:level2categoryitem" element={<LMPGuide />} />
                        <Route path="/guidelines/construction-management-system/:level1category/:level2categoryitem" element={<CMSGuide />} />
                        <Route path="/guidelines/helpdesk/:level1category/:level2categoryitem" element={<HelpdeskGuide />} />
                        <Route path="/guidelines/asset-management-system/:level1category/:level2categoryitem" element={<AMSGuide />} />
                        <Route path="/guidelines/spe-intranet/:level1category/:level2categoryitem" element={<SPEIntranetGuide />} />
                        <Route path="/guidelines/audit-inspection/:level1category/:level2categoryitem" element={<AuditGuide />} />
                        <Route path="/guidelines/inventory-management-system/:level1category/:level2categoryitem" element={<IMSGuide />} />
                        <Route path="/guidelines/approval-system/:level1category/:level2categoryitem" element={<ASGuide />} />

                        <Route path="/guidelines/learning-management-portal/:level1category/:level2categoryitem/:level3categoryitem" element={<LMPGuide />} />
                        <Route path="/guidelines/construction-management-system/:level1category/:level2categoryitem/:level3categoryitem" element={<CMSGuide />} />
                        <Route path="/guidelines/helpdesk/:level1category/:level2categoryitem/:level3categoryitem" element={<HelpdeskGuide />} />
                        <Route path="/guidelines/asset-management-system/:level1category/:level2categoryitem/:level3categoryitem" element={<AMSGuide />} />
                        <Route path="/guidelines/spe-intranet/:level1category/:level2categoryitem/:level3categoryitem" element={<SPEIntranetGuide />} />
                        <Route path="/guidelines/audit-inspection/:level1category/:level2categoryitem/:level3categoryitem" element={<AuditGuide />} />
                        <Route path="/guidelines/inventory-management-system/:level1category/:level2categoryitem/:level3categoryitem" element={<IMSGuide />} />
                        <Route path="/guidelines/approval-system/:level1category/:level2categoryitem/:level3categoryitem" element={<ASGuide />} />
                    </Routes>
                    {!excludedPaths.some((path) => location.pathname.includes(path)) && (
                        <ClientsAcrossTheGlobe />
                    )}
                    {!location.pathname.startsWith('/guidelines') && <Footer />}
                    <ScrollToTop />
                </>
            )}
        </ApiDataProvider>
    );
};

export default MasterPage;