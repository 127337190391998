import React, { createContext, useState, useEffect, useCallback } from "react";
import axios from "axios";

export const ApiDataContext = createContext();

const globalConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
};

const ApiDataProvider = ({ children }) => {
  const [helpCenterData, setHelpCenterData] = useState([]);
  const [productPageData, setProductPageData] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [jobOpeningData, setJobOpeningData] = useState([]);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark-mode-body");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async (urls, setStates) => {
    try {
      setIsLoading(true);
      const responses = await Promise.all(urls.map((url) => axios.get(url, globalConfig)));
      setStates.forEach((setState, index) => setState(responses[index].data));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(
      [
        "https://api.sharepointempower.com/api/sharepoint/getproductpage",
        "https://api.sharepointempower.com/api/sharepoint/getFAQpage",
        "https://api.sharepointempower.com/api/sharepoint/getJobOpeningPage",
      ],
      [setProductPageData, setFaqData, setJobOpeningData]
    );
  }, [fetchData]);

  useEffect(() => {
    document.body.classList.toggle("dark-mode-body", theme === "dark-mode-body");
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark-mode-body" : "light"));
  };

  const fetchHelpCenterData = async (finalFormatted) => {

    try {
      if (helpCenterData && helpCenterData.length > 0) {
        const isDataExists = helpCenterData.filter((item) => item.projectName === finalFormatted);
        if (isDataExists.length !== 0) {
          return;
        }
      }

      setIsLoading(true);
      const apiURL = `https://api.sharepointempower.com/api/sharepoint/getproject?liveprojectName=${encodeURIComponent(finalFormatted)}`;
      const response = await axios.get(apiURL, globalConfig);

      const allData = [...helpCenterData, ...response.data];
      setHelpCenterData(allData);
    } catch (error) {
      console.error("Error fetching helpCenterData:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.indexOf('guidelines') !== -1) {
      const parts = currentURL.split('/');
      const targetSegment = parts[parts.indexOf('guidelines') + 1];
      const formatted = targetSegment.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      const finalFormatted = formatted === "Spe Intranet" ? "SPE Intranet" : formatted;
      if (finalFormatted) {
        fetchHelpCenterData(finalFormatted);
      }
    }
  }, [window.location.href]);

  return (
    <ApiDataContext.Provider
      value={{
        helpCenterData,
        productPageData,
        faqData,
        jobOpeningData,
        theme,
        toggleTheme,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </ApiDataContext.Provider>
  );
};

export default ApiDataProvider;