import React, { useEffect } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ReactHelmetTag from '../commonComponents/reactHelmet/ReactHelmetTag';
import ProductPage from './productPage/ProductPage';
import Megamenu from '../megamenu/Megamenu';

const AuditInspection = () => {

  useEffect(() => {
    document.title = "Audit Inspection";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Megamenu />
      <ReactHelmetTag
        link="https://www.sharepointempower.com/audit-inspection"
        metaTitle="Inspection management software to manage risk, incident and action"
        metaDescription="Enhance productivity and accountability with our SharePoint Action Management System. Streamline task tracking, assignment, and monitoring processes for efficient action management."
        metaKeywords="Audit inspection, Sharepoint Audit inspection, Inspection management software, SharePoint risk management system, SharePoint action management system, SharePoint incident management system"
      />
      <LazyLoadComponent>
        <ProductPage project="Audit Inspection" />
      </LazyLoadComponent>
    </div >
  );
};

export default AuditInspection;