import React from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ReactHelmetTag from '../../commonComponents/reactHelmet/ReactHelmetTag';
import AllProjects from '../../commonComponents/userGuide/Allprojects';

const AMSGuide = () => {
    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/guidelines/asset-management-system"
                metaKeywords="Asset management system guidelines, Asset management system installation steps, Asset management system help center, Sharepoint asset management system"
            />

            <section>
                <LazyLoadComponent>
                    <AllProjects project="Asset Management System" path="/asset-management-system"/>
                </LazyLoadComponent>
            </section >
        </div >
    )
};

export default AMSGuide;