import React, { useState, useEffect } from 'react';
import useWindowSize from '../commonComponents/commonFunction/UseWindowSize';
import './Banner.css';

const Banner = () => {

    const [isScrolled, setIsScrolled] = useState(false);
    const { windowWidth } = useWindowSize();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsScrolled(scrollTop > 200);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div>
                <div className="container-fluid">
                    <div className="banner-container">
                        <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
                            <div className='loading-image'>
                                <>
                                    {windowWidth > 480 ?
                                        <video id='banner-video' height="100%" width="100%" autoPlay loop muted playsInline className={`banner-video-mask ${isScrolled ? 'scrolled-banner' : ''} pointer-events-none`}>
                                            <source src={require('../../assets/videos/LandingPageVideo/sharepointEmpowerLandingVideo.mp4')} type='video/mp4' />
                                        </video>
                                        :
                                        <video id='banner-video' height="100%" width="100%" autoPlay loop muted playsInline className={`banner-video-mask ${isScrolled ? 'scrolled-banner' : ''} pointer-events-none`}>
                                            <source src={require('../../assets/videos/LandingPageVideo/sharepointEmpower-mobile.mp4')} type='video/mp4' />
                                        </video>
                                    }
                                </>
                            </div>
                            <div style={windowWidth > 480 ? { position: 'absolute', bottom: 0, right: 10, width: '20%' } : { position: 'absolute', bottom: 10, right: 10, width: '30%' }} className={`microsoft-icon-banner-video-mask ${isScrolled ? 'scrolled-banner' : ''}`}>
                                <div className='flex justify-content-end'>
                                    <img
                                        className="microsoft-icon w-7"
                                        fetchpriority="high"
                                        src={require("../../assets/images/microsoftSolutionsPartner/microsoft-solution-partner-white.webp")}
                                        alt="Microsoft-solution-partner"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Banner;