import React, { useEffect, useState, useContext, useRef } from 'react';
import { ApiDataContext } from '../../../context/ApiDataContext';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CRMAPI } from '../commonFunction/CrmApi';
import { format } from 'date-fns';
import './ContactUsForm.css';

const ContactUsForm = ({ isQuotation }) => {
    const { productPageData } = useContext(ApiDataContext);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [extraCheckbox, setExtraCheckbox] = useState(isQuotation || false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const form = useRef();

    const titlesAndPlans = productPageData?.map(({ projectTitle, planDescription, plan }) => {
        const price = parseInt(plan.match(/\d+/)[0], 10);
        return { projectTitle, planDescription, price };
    });

    useEffect(() => {
        document.title = "Contact us";
        window.scrollTo(0, 0);
    }, []);

    const now = new Date();
    const year = String(now.getFullYear()).slice(2);
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    const randomNum = `${year}${month}${day}${hours}${minutes}`;
    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        userCompany: '',
        userLocation: '',
        userService: [],
        userProject: '',
        CustomizationDetail: [],
        Date: "",
        randomNum: randomNum,
    });

    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = `${String(currentDate.getMonth() + 1).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}/${currentDate.getFullYear()}`;
        setFormData(prevFormData => ({
            ...prevFormData,
            Date: formattedDate
        }));
    }, []);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateForm = async () => {
        if (!executeRecaptcha) {
            console.log('Execute reCAPTCHA not yet available');
            return;
        }

        setSubmitted(true);

        const isFormInvalid = [
            !formData.userName.trim(),
            !isValidEmail(formData.userEmail.trim()),
            !formData.userCompany.trim(),
            !formData.userLocation.trim(),
            formData.userService.length === 0,
            (!extraCheckbox && !formData.userProject.trim())
        ].some(Boolean);

        if (isFormInvalid) return;

        setVisibleDialog(true);

        setTimeout(() => {
            setVisibleDialog(false);
            form.current.reset();
            setFormData({
                userName: '',
                userEmail: '',
                userCompany: '',
                userLocation: '',
                userService: [],
                userProject: '',
            });
            setSubmitted(false);
        }, 3000);

        if (extraCheckbox) {
            await handleQuotationSubmission();
        } else {
            await handleContactSubmission();
        }
    };

    const handleQuotationSubmission = async () => {

        const myHeaders = new Headers({
            "Content-Type": "application/json;odata=verbose",
            "Authorization": "Bearer 4352345342"
        });

        const apiData = {
            "Title": formData.userName,
            "EmailAddress": formData.userEmail,
            "Companyname": formData.userCompany,
            "Companylocation": formData.userLocation,
            "Total": formData.totalPrice,
            "SelectType": "Quotation",
            "Date": formData.Date,
            "Discount": "0",
            "Note": "This Quotation is valid for 7 Days from the date of issuance.",
            "BillDuration": "Yearly",
            "BalanceOwned": formData.totalPrice,
            "MoveToClientTracker": "False",
            "CurrencyId": "1",
            "CustomizationDetail": JSON.stringify(formData.CustomizationDetail),
            "Price": JSON.stringify(formData.userService),
            "RandomBillNO": formData.randomNum,
            "PaymentMode": "false",
        };

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(apiData),
        };

        // fetch("http://localhost:5220/api/sharepoint/qaPostquotationdata", requestOptions)
        fetch("https://api.sharepointempower.com/api/sharepoint/postquotationdata", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

        const dateTime = new Date();
        const formatDate = (date) => {
            return format(new Date(date), "EEEE, dd/MM/yyyy HH:mm:ss");
        };

        const data = {
            name: formData.userName,
            email: formData.userEmail,
            company: formData.userCompany,
            location: formData.userLocation,
            service: formData.userService?.map(service => service.projectTitle).join(', '),
            description: `Quotation downloaded by ${formData.userName} /<b>Total Amount:</b> ${formData.totalPrice} USD /<b>Date :</b> ${formatDate(dateTime)} (IST) /${formData.userProject}`,
        };
        await CRMAPI(data);

        setExtraCheckbox(false);
    };

    const handleContactSubmission = async () => {
        const data = {
            name: formData.userName,
            email: formData.userEmail,
            company: formData.userCompany,
            location: formData.userLocation,
            service: formData.userService?.map(service => service.projectTitle).join(', '),
            description: formData.userProject
        };
        await CRMAPI(data);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleMultiSelectChange = (e) => {
        const selectedServices = e.value;

        const updatedCustomizationDetail = selectedServices?.map(service => ({
            Customization: `M365 - ${service.projectTitle} * ${service.planDescription}`,
            Charges: service.price * 12,
        }));

        const totalPrice = selectedServices?.reduce((total, service) => total + service.price * 12, 0);

        setFormData(prevFormData => ({
            ...prevFormData,
            userService: selectedServices,
            CustomizationDetail: updatedCustomizationDetail,
            totalPrice,
            balanceOwned: totalPrice,
        }));
    };

    return (
        <>
            <form ref={form} name="ContactForm" autoComplete='off'
                className="contact-us-form">
                <div className='flex row'>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <label className='font-semibold' htmlFor="fname">Name <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" id="fname" name="userName" placeholder='Enter Your Name' style={{ fontSize: '0.855rem', fontWeight: 500 }} onChange={handleChange} required />
                        {submitted && !formData?.userName && <small className="p-error"> Please enter your name</small>}
                    </div>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <label className='font-semibold' htmlFor="email">Company Email Address<span style={{ color: 'red' }}>*</span></label>
                        <input type="text" id="email" name="userEmail" placeholder='Enter Company Email Address' style={{ fontSize: '0.855rem', fontWeight: 500 }} onChange={handleChange} required />
                        {submitted && !formData?.userEmail && <small className="p-error"> Please enter your valid email</small>}
                        {submitted && formData?.userEmail && !isValidEmail(formData.userEmail) && <span className="p-error">Invalid email address.</span>}

                    </div>
                </div>
                <div className='flex row'>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <label className='font-semibold' htmlFor="company">Your Company<span style={{ color: 'red' }}>*</span></label>
                        <input type="text" id="company" name="userCompany" placeholder='Enter Your Organization Name' style={{ fontSize: '0.855rem', fontWeight: 500 }} onChange={handleChange} required />
                        {submitted && !formData?.userCompany && <small className="p-error"> Please enter your company name</small>}
                    </div>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <label className='font-semibold' htmlFor="location">Your Location<span style={{ color: 'red' }}></span></label>
                        <input type="text" id="location" name="userLocation" placeholder='Enter Your Company Location' style={{ fontSize: '0.855rem', fontWeight: 500 }} onChange={handleChange} required />
                        {submitted && !formData?.userLocation && <small className="p-error"> Please enter your location</small>}
                    </div>
                </div>

                <label className='font-semibold' htmlFor="service">What kind of products are you looking for? <span style={{ color: 'red' }}>*</span></label>{submitted && !formData?.userService.length > 0 && <small className="p-error"> Please select product</small>}
                <br />
                <MultiSelect
                    value={formData.userService}
                    options={titlesAndPlans}
                    onChange={handleMultiSelectChange}
                    optionLabel="projectTitle"
                    display="chip"
                    className='contact-us-product-select'
                    placeholder="Select Products/Services"
                    panelClassName='contact-product-dropdown'
                    filter
                />

                <div className='quotation-checkbox'>
                    <input
                        type="checkbox"
                        id="extraCheckbox"
                        name="extraCheckbox"
                        checked={extraCheckbox}
                        onChange={() => setExtraCheckbox(!extraCheckbox)}
                    />
                    <label className='font-semibold ml-2' htmlFor="extraCheckbox"> <b> <mark> Need a Quotation? </mark> </b></label>
                </div>
                <label className='font-semibold' htmlFor="project">Tell us more about your requirements<span style={{ color: 'red' }}>*</span> {submitted && !extraCheckbox && !formData.userProject && <small className="p-error"> Please write your requirements</small>}</label>
                <textarea className="form-control text-area" onChange={handleChange} name='userProject' rows="2" required></textarea>
                <br></br>
                <div className='flex justify-content-between align-items-center contact-btn-flex flex-wrap'>
                    <button type='button' id="btn-submit" onClick={validateForm} className="button-gradient">
                        Submit
                    </button>
                </div>
            </form>
            <Dialog visible={visibleDialog} className='contact-us-success-modal'>
                <img src={require("../../../assets/images/icons/accept-icon.png")} className='sent-mail-alert-image' alt='Success' />
            </Dialog >
        </>
    );
};

export default ContactUsForm;