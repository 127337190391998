import React, { useEffect, useState, useContext } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ApiDataContext } from '../../../context/ApiDataContext';
import { Link } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';
import ReactImageZoom from 'react-image-zoom';
import useWindowSize from '../../commonComponents/commonFunction/UseWindowSize';
import './ProductPage.css';

const ProductPage = ({ project }) => {
  const { productPageData, theme } = useContext(ApiDataContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { windowWidth } = useWindowSize();

  const data = productPageData?.filter((item) => item.projectTitle === project);
  let updateprojectname = data[0]?.projectTitle;

  if (data[0]?.projectName?.includes('Management') && data[0]?.projectTitle?.includes('System')) {
    updateprojectname = data[0]?.projectTitle.replace('System', '').trim();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getLogoForProduct(productName) {
    switch (productName) {
      case 'Learning Management Portal':
        return theme === 'light' ? require('../../../assets/images/icons/productIcons/lmp-w.png') : require('../../../assets/images/icons/productIcons/lmp-b.png');
      case 'Asset Management System':
        return theme === 'light' ? require('../../../assets/images/icons/productIcons/ams-w.png') : require('../../../assets/images/icons/productIcons/ams-b.png');
      case 'Audit Inspection':
        return theme === 'light' ? require('../../../assets/images/icons/productIcons/audit-w.png') : require('../../../assets/images/icons/productIcons/audit-b.png');
      case 'Construction Management System':
        return theme === 'light' ? require('../../../assets/images/icons/productIcons/cms-w.png') : require('../../../assets/images/icons/productIcons/cms-b.png');
      case 'SPE Intranet':
        return theme === 'light' ? require('../../../assets/images/icons/productIcons/spe-Intranet-w.png') : require('../../../assets/images/icons/productIcons/spe-Intranet-b.png');
      case 'HelpDesk System':
        return theme === 'light' ? require('../../../assets/images/icons/productIcons/hd-w.png') : require('../../../assets/images/icons/productIcons/hd-b.png');
      case 'Inventory Management System':
        return theme === 'light' ? require('../../../assets/images/icons/productIcons/ims-w.png') : require('../../../assets/images/icons/productIcons/ims-b.png');
      case 'Approval System':
        return theme === 'light' ? require('../../../assets/images/icons/productIcons/as-w.png') : require('../../../assets/images/icons/productIcons/as-b.png');
      default:
        return null;
    };
  };

  const zoomPropsProductImage = {
    width: 600,
    zoomWidth: 500,
    scale: 1.1,
    zoomPosition: 'right',
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div>
        <div className="container-fluid product-page">
          {data?.length > 0 ? (
            <div className='container'>
              <div className='flex flex-wrap justify-content-between align-items-center first-section-margin-top-for-navbar relative'>
                <div className='flex flex-wrap align-items-center'>
                  <img className="product-icons" src={getLogoForProduct(data[0]?.projectTitle)} alt="loading" />
                  <h2 className='product-name'>{updateprojectname}</h2>
                  <a href={data[0]?.certifiedLink} target='_blank' rel="noopener noreferrer"><img className='certified-image' src={data[0]?.certifiedImage} alt="loading" ></img></a>
                </div>
                <div className='faq-button flex align-items-center hidden'>
                  <Link to="/faqs" className='hidden md:block'>
                    <i className="pi pi-question-circle mr-1" />
                    <span className='m-0'>FAQs</span>
                  </Link>
                </div>
              </div>
              <div className='mt-3 product-know-background'>
                <div className='row product-know'>
                  <div className='col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5'>
                    <div>
                      <p>{data[0]?.productKnowDescription}</p>
                      {windowWidth >= 450 ? <div className=' product-know-button icon-btn row'>
                        <div className=' text-lg  col-6 col-md-6 col-lg-6'>
                          <a href={data[0]?.download} target="_blank" rel="noopener noreferrer">
                            <i className="pi pi-download px-2 text-lg"></i>Download
                          </a>
                        </div>
                        <div className=' text-lg  col-6 col-md-6 col-lg-6'>
                          <Link to={data[0]?.helpCenter}>
                            <i className="pi pi-info-circle px-2 text-lg"></i>Help Center
                          </Link>
                        </div>
                        <div className=' text-lg  col-6 col-md-6 col-lg-6'>
                          {data[0]?.certifiedLink && (
                            <a href={data[0]?.productCertificateLink} target="_blank" rel="noopener noreferrer">
                              <i className="pi pi-trophy px-2 text-lg"></i>Award
                            </a>
                          )}
                        </div>
                        <div className=' text-lg  col-6 col-md-6 col-lg-6'>
                          <Link to='/contact-us'>
                            <i className="pi pi-address-book px-2 text-lg"></i>Get in Touch
                          </Link>
                        </div>
                      </div>
                        :
                        <div className='product-know-button flex'>
                          <a className='px-3 py-2 w-3' href={data[0]?.download} target="_blank" rel="noopener noreferrer">
                            <i className="pi pi-download text-xl"></i>
                          </a>
                          <Link className='px-3 py-2 w-3' to={data[0]?.helpCenter}>
                            <i className="pi pi-info-circle text-xl"></i>
                          </Link>
                          {data[0]?.certifiedLink && (
                            <a className='px-3 py-2 w-3' href={data[0]?.productCertificateLink} target="_blank" rel="noopener noreferrer">
                              <i className="pi pi-trophy text-xl"></i>
                            </a>
                          )}
                        </div>}
                    </div>
                  </div>
                  <div className='col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7 m-0 flex-column' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <LazyLoadImage
                      className="product-image"
                      src={`${require(`../../../assets/images/productPageImage/productFeaturesTemplate/${data[0]?.productKnowImage}.jpeg`)}`}
                      alt="banner"
                      delayTime={100}
                      onClick={handleImageClick}
                      visibleByDefault={`${require(`../../../assets/images/productPageImage/productFeaturesTemplate/${data[0]?.productKnowImage}.jpeg`)}`}
                    />
                    <Dialog visible={isModalOpen} onHide={() => setIsModalOpen(false)} dismissableMask={() => setIsModalOpen(true)} maximizable >
                      <img src={`${require(`../../../assets/images/productPageImage/productFeaturesTemplate/${data[0]?.productKnowImage}.jpeg`)}`} alt="Full-sized" style={{ width: '100%', height: 'auto' }} />
                    </Dialog>
                  </div>
                </div>
              </div>
              <div>
                <h4 className='d-flex justify-content-center h5-title-lm text-align-center h5-title-anim'>Why&nbsp;{data[0]?.projectTitle}?</h4>
                <p className='product-description-color description-font-size' dangerouslySetInnerHTML={{ __html: data[0]?.productDescription }}></p>
                <div className='row mt-4'>
                  <div className='col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 relative product-mockup'>
                    {windowWidth >= 450 ? <ReactImageZoom className="cursor-pointer" {...zoomPropsProductImage} img={`${require(`../../../assets/images/productPageImage/productMockup/${data[0]?.productImage}.png`)}`} /> :
                      <LazyLoadImage
                        className='cursor-pointer'
                        src={`${require(`../../../assets/images/productPageImage/productMockup/${data[0]?.productImage}.png`)}`}
                        alt='productmockup'
                        delayTime={100}
                        visibleByDefault={`${require(`../../../assets/images/productPageImage/productMockup/${data[0]?.productImage}.png`)}`}
                      />
                    }
                  </div>
                  <div className='col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 product-point'>
                    <p className='product-description-color px-2' dangerouslySetInnerHTML={{ __html: data[0]?.productPoints }}></p>
                  </div>
                </div>
              </div>
              <div className="container spfx-note">
                <span>Our apps are developed using modern SharePoint Framework-based solutions, and futuristics, and It is modern technology that will be supported in the future and recommended by Microsoft. </span>
                <span>Sharepoint-hosted and Provider-hosted apps will be discontinued in the future. However, our app is already <b> modern SharePoint Framework Solutions </b> as recommended by <b>Microsoft.</b></span>
              </div>
              <div className='py-3'>
                <h4 className='d-flex justify-content-center h5-title-lm text-align-center h5-title-anim'>Top Features</h4>
                <div className='row d-flex justify-content-center product-feature-row-1-anim relative product-features'>
                  {JSON.parse(data[0]?.features)?.map((e, index) => (
                    <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 flex justify-content-center align-items-center text-center border-1 m-sm-3 m-1 border-round-sm product-page-features-background' key={index}>
                      <div className='py-3'>
                        <img src={`https://www.sharepointempower.com/assets/Images/product-page-images/${e?.icon}`} alt="loading" />
                        <p className='mb-0'>{e.name}</p>
                        <p className='mt-0'>{e.subtitle}</p>
                      </div>
                    </div>))}
                </div>
              </div>
              <div className='certified'>
                <h4 className='d-flex justify-content-center h5-title-lm text-align-center h5-title-anim'> {data[0]?.certifiedHeader}</h4>
                <h6 className='d-flex justify-content-center'>{data[0]?.certifiedSubHeader}</h6>
                <p className='mt-3 description-font-size'>{data[0]?.certifiedDescription}</p>
              </div>
            </div>
          ) : (
            <>
              <div className='container'>
                <div className=' flex flex-wrap first-section-margin-top-for-navbar align-items-center'>
                  <Skeleton shape='circle' size='2.5rem' className='m-1' />
                  <Skeleton width="20%" height="30px" className='m-1' />
                </div>

                <div className='mt-5 product-page-background'>
                  <div className='row product-know'>
                    <div className='col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                      <div>
                        <Skeleton width="70%" height="50px" className='m-1' />
                        <div className='my-5 flex  '>
                          <Skeleton width="15%" height="40px" className='m-1' />
                          <Skeleton width="15%" height="40px" className='m-1' />
                          <Skeleton width="15%" height="40px" className='m-1' />
                        </div>
                      </div>
                    </div>
                    <div className='col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 m-0 ' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Skeleton width="100%" height="100px" className='m-1' />
                    </div>
                  </div>
                </div>
                <div className='py-3'>
                  <div className='d-flex justify-content-center text-align-center'>
                    <Skeleton width="50%" height="40px" className='m-1' />
                  </div>
                  <Skeleton width="100%" height="90px" className='m-3 px-2' />
                  <div className='row mt-4'>
                    <div className='col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Skeleton width="80%" height="250px" className='m-1' />
                    </div>
                    <div className='col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 product-point'>
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <Divider type="solid" className='container m-auto opacity-50' />
      </div>
    </>
  );
};

export default ProductPage;