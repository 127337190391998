import React, { useEffect } from 'react';
import ReactHelmetTag from '../commonComponents/reactHelmet/ReactHelmetTag';
import ProductOverview from '../products/productPage/ProductOverview';
import Banner from './Banner';
import Megamenu from '../megamenu/Megamenu';

const HomePage = () => {
  // const [isCookieModalVisible, setIsCookieModalVisible] = useState(false);

  useEffect(() => {
    document.title = "SharepointEmpower Pvt. Ltd.";
    window.scrollTo(0, 10);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    // Check if cookies have been accepted  
    // if (!document.cookie.split('; ').find(row => row.startsWith('accepted_cookies='))) {
    //   setIsCookieModalVisible(true);
    // }

  }, []);

  // const handleCookieAccept = () => {
  //   const now = new Date();
  //   now.setTime(now.getTime() + 1000 * 60 * 60 * 24 * 365); // One year
  //   document.cookie = `accepted_cookies=yes;expires=${now.toUTCString()};path=/`;
  //   setIsCookieModalVisible(false);
  // };

  // const handleCookieDecline = () => {
  //   setIsCookieModalVisible(false);
  // };

  return (
    <>
      <Megamenu />
      <div id="homepage">
        <ReactHelmetTag
          link="https://www.sharepointempower.com"
          metaKeywords="SharepointEmpower pvt ltd, Sharepointempower company, SharepointEmpower Rajkot, Rajkot SharepointEmpower Pvt. ltd., Sharepointempower homepage"
        />
        <Banner />
        <div className="container mt-4">
          <div className="flex justify-content-center">
            <h3 className="all-first-section-margin-top-for-navbar" style={{ fontFamily: 'Verdana (sans-serif)', fontWeight: '600' }}>
              Explore Our M365 Certified & Modern SharePoint Framework (SPFx) Solutions
            </h3>
          </div>
          <ProductOverview />
        </div>
        <hr className="w-9 mx-auto" />
      </div>

      {/* {isCookieModalVisible && (
        <div className="modal fade show cookie-container-anim cookieModal" style={{ display: 'block' }} role="dialog">
          <div className="modal-dialog cookie-row" role="document">
            <div className="modal-content relative bg-black border-round">
              <div className="modal-body text-center">
                <div>
                  <h5 className="text-xl text-white font-semibold">Here’s how we use cookies</h5>
                </div>
                <p className="my-4 text-white">
                  We aim to bring you the best, most personalized experience. Functional, Analytics/Performance, and Advertising/Targeting optional cookies help to make this possible.
                </p>
                <div className="buttons flex flex-row align-items-center justify-content-center flex-wrap">
                  <button
                    className="btn btn-cookie-custom-allow border-solid p-2 m-1 w-3"
                    onClick={handleCookieAccept}
                  >
                    Allow
                  </button>
                  <button
                    className="btn btn-cookie-custom-decline border-solid p-2 m-1 w-3"
                    onClick={handleCookieDecline}
                  >
                    Decline
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default HomePage;
