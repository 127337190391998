
import React from 'react';;

const GettingStarted = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_207_7)">
                <path stroke='gray' d="M12.5 0V18.75H17.8125L18.4375 20.9375L19.0625 22.8125H19.375L23.125 20.9375L28.75 26.5625L26.875 30.3125V30.625L28.75 31.25L30.9375 31.875V32.5L50 21.875L12.5 0ZM14.0625 32.8125C13.4375 32.8125 12.8125 33.125 12.5 33.4375C11.5625 34.0625 10.9375 35 10.9375 35.9375C10.9375 36.875 11.5625 38.125 12.5 38.4375C12.8125 38.75 13.4375 39.0625 14.0625 39.0625C15.9375 39.0625 17.1875 37.8125 17.1875 35.9375C17.1875 34.0625 15.9375 32.8125 14.0625 32.8125Z" fill="gray" />
                <path stroke='gray' d="M28.125 37.5V34.375L24.6875 33.125C24.375 32.1875 24.0625 31.25 23.4375 30.3125L25 27.1875L22.8125 25L19.6875 26.5625C18.75 25.9375 17.8125 25.625 16.875 25.3125L15.625 21.875H12.5L11.25 25.3125C10.3125 25.625 9.375 25.9375 8.4375 26.5625L5.3125 25L3.125 27.1875L4.6875 30.625C4.0625 31.5625 3.75 32.5 3.4375 33.4375L0 34.375V37.5L3.4375 38.75C3.75 39.6875 4.0625 40.625 4.6875 41.5625L3.125 44.6875L5.3125 46.875L8.75 45.3125C9.6875 45.9375 10.625 46.25 11.5625 46.5625L12.5 50H15.625L16.875 46.5625C17.8125 46.25 18.75 45.9375 19.6875 45.3125L22.8125 46.875L25 44.6875L23.4375 41.25C24.0625 40.3125 24.375 39.375 24.6875 38.4375L28.125 37.5ZM14.0625 42.1875C10.625 42.1875 7.8125 39.375 7.8125 35.9375C7.8125 32.5 10.625 29.6875 14.0625 29.6875C17.5 29.6875 20.3125 32.5 20.3125 35.9375C20.3125 39.375 17.5 42.1875 14.0625 42.1875Z" fill="gray" />
            </g>
            <defs>
                <clipPath id="clip0_207_7">
                    <rect width="50" height="50" fill="gray" />
                </clipPath>
            </defs>
        </svg>
    )
};

export default GettingStarted;
