import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import ContactUsForm from './ContactUsForm';
import ReactHelmetTag from '../reactHelmet/ReactHelmetTag';
import Megamenu from '../../megamenu/Megamenu';

function ContactUs() {

    useEffect(() => {
        document.title = "Contact Us";
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Megamenu />
            <div className='mt-7'>
                <ReactHelmetTag
                    link="https://www.sharepointempower.com/contact-us"
                    metaKeywords="contact us form"
                />
                <div className="fixed-top-container">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col col-9 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div id="first-section-margin-top-for-navbar">
                                        <h1 className="h1-tag-lm h1-heading">Get in Touch with Us</h1>
                                    </div>
                                </div>
                                <div className="col col-3 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 flex justify-content-end align-items-center">
                                    <div id="first-section-margin-top-for-navbar" className="faq-button d-flex">
                                        <Link to="/faqs">
                                            <i className="pi pi-question-circle mr-1" />
                                            <span className='m-0'>FAQs</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container-fluid">
                        <div className="container mt-4">
                            <div className="form-container py-2">
                                <div className="row">
                                    <div className="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                        <div className='px-4'>
                                            <ContactUsForm isQuotation={true} />
                                        </div>
                                    </div>
                                    {<div className="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                        <div className="form-img-container">
                                            <LazyLoadImage
                                                src={require("../../../assets/images/contactUs/contact-us-page-image.jpeg")}
                                                alt="contact-us-form"
                                                effect="blur"
                                                visibleByDefault={require("../../../assets/images/contactUs/contact-us-page-image.jpeg")}
                                            />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
            </div>
        </>
    );
};

export default ContactUs;