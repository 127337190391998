import React, { useEffect } from 'react';
import { Link } from 'react-router-dom/dist';
import ProductOverview from '../productPage/ProductOverview';
import Megamenu from '../../megamenu/Megamenu';
import './Allproducts.css';

export default function Allproducts() {

    useEffect(() => {
        document.title = "Products";
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Megamenu />
            <div className='all-product-page'>
                <div className='d-flex flex-row container justify-content-between mt-8'>
                    <div>
                        <h3 className='all-first-section-margin-top-for-navbar' style={{ fontFamily: 'Verdana (sans-serif)', fontWeight: '600' }}>Explore Our M365 Certified & Modern SharePoint Framework (SPFx) Solutions</h3>
                    </div>
                    <div className="product-quotation-button d-flex align-items-center pr-4">
                        <Link to="/faqs">
                            <i className="pi pi-question-circle mr-1" />
                            <span className='m-0'>FAQs</span>
                        </Link>
                    </div>
                </div>
                <ProductOverview />
            </div>
        </>
    );
};
