import React from 'react';

const UpgradeAppGuide = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 50 50" fill="gray" xmlns="http://www.w3.org/2000/svg">
            <path stroke="gray" d="M14.25 22.5C15.25 17.5 19.75 13.75 25 13.75C28.75 13.75 31.75 15.5 33.75 18.25L38 13.25C35 9.75 30.25 7.5 25 7.5C16.25 7.5 9 14 7.75 22.5H2.5L11.25 32.5L20 22.5H14.25ZM38.75 17.5L30 27.5H35.75C34.5 32.5 30.25 36.25 25 36.25C21.25 36.25 18.25 34.5 16.25 31.75L12 36.5C15 40.25 19.75 42.5 25 42.5C33.75 42.5 41 36 42.25 27.5H47.5L38.75 17.5Z" fill="gray" />
        </svg>
    )
};

export default UpgradeAppGuide;