export const OUR_VALUE_DATA = [
    {
        cardTitle: 'pi pi-globe',
        cardSubtitle: "Clients across the globe",
        cardContent: "We take great pride in our ability to communicate effectively with clients from diverse cultural backgrounds. No matter where you are located, our commitment to quality and customer satisfaction remains the same."
    },
    {
        cardTitle: "500+",
        cardSubtitle: "Happy Clients",
        cardContent: "We take great pride in the fact that over 500 businesses have chosen SharepointEmpower as their trusted partner."
    },
    {
        cardTitle: "20+",
        cardSubtitle: "Technologies",
        cardContent: "At SharepointEmpower, we leverage over 20 cutting-edge technologies to provide innovative solutions that help businesses achieve their goals and stay ahead of the competition."
    },
    {
        cardTitle: "5K+",
        cardSubtitle: "Projects",
        cardContent: "We take great pride in having completed over 5,000 successful projects for businesses across a wide range of industries."
    }
];

export const KEY_VALUES_DATA = [
    {
        cardTitle: "Innovation",
        cardContent: "We believe in the power of innovation and are committed to staying up-to-date with the latest trends and advancements in our industry. This allows us to deliver cutting-edge solutions that are at the forefront of our industry."
    },
    {
        cardTitle: "Collaboration",
        cardContent: "We believe that teamwork and collaboration are essential to achieving success, which is why we work closely with our clients to understand their unique needs and develop customized solutions that meet their goals and objectives."
    },
    {
        cardTitle: "Integrity",
        cardContent: "We are committed to operating with the highest level of integrity and honesty in all that we do. We believe that building trust with our clients and team members is essential to achieving long-term success."
    }
];

export const BENEFITS_DATA = [
    {
        iconName: "calendar-icon",
        title: "5 Days working",
    },
    {
        iconName: "flexible-hours-icon",
        title: "Flexible working hours",
    },
    {
        iconName: "reward-icon",
        title: "Rewards and Appreciation",
    },
    {
        iconName: "workplace-icon",
        title: "Amazing workplace",
    },
    {
        iconName: "growth-icon",
        title: "Personal and Career Growth",
    },
    {
        iconName: "amazing-team-icon",
        title: "Amazing and supportive colleagues",
    },
];