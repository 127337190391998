import React, { useState, useEffect } from 'react';
import "./ScrollToTop.css";

function ScrollToTop() {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => {
            window.removeEventListener('scroll', toggleVisible);
        };
    }, []);

    return (
        <div>
            <button
                onClick={scrollUp}
                style={{ display: visible ? 'inline' : 'none' }}
                id="myBtn"
                className="scroll-button"
                title="Go to top"
            >
                <i className="pi pi-angle-up"></i>
            </button>
        </div>
    );
}

export default ScrollToTop;