import React, { useEffect } from "react";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ReactHelmetTag from "../commonComponents/reactHelmet/ReactHelmetTag";
import ProductPage from "./productPage/ProductPage";
import Megamenu from "../megamenu/Megamenu";

const ConstructionManagementSystem = () => {

  useEffect(() => {
    document.title = "Construction Management System";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Megamenu />
      <ReactHelmetTag
        link="https://www.sharepointempower.com/construction-management-system"
        metaTitle="Sharepoint construction management system"
        metaDescription="Efficiently manage construction projects with our SharePoint Construction Management System. Streamline communication, document control, and project tracking for improved collaboration and productivity"
        metaKeywords="construction management software, construction project management software, project management software construction, software for construction project management, management software for construction, best construction management software, construction management system"
      />
      <LazyLoadComponent>
        <ProductPage project="Construction Management System" />
      </LazyLoadComponent>
    </div>
  );
};

export default ConstructionManagementSystem;
