import React, { useEffect } from 'react';
import PageHeadingContent from '../../commonComponents/pageHeadingContent/PageHeadingContent';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactHelmetTag from '../../commonComponents/reactHelmet/ReactHelmetTag';
import { KEY_VALUES_DATA } from '../../../utils/data';
import Megamenu from '../../megamenu/Megamenu';
import './OurCommitment.css';

const OurCommitment = () => {

    useEffect(() => {
        document.title = "Our commitment";
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Megamenu />
            <div className='content-div'>
                <ReactHelmetTag
                    link="https://www.sharepointempower.com/our-commitment"
                    metaKeywords="Our commitment, our goals, our vision, our mission"
                />
                <PageHeadingContent heading="Our Commitment" subtitle="Our promise: Quality, Reliability, and Satisfaction" />
                <div className="our-commitment-banner-container">
                    <div className="our-commitment-banner-image">
                        <div className="our-commitment-banner-text-overlay">
                            <p className="our-commitment-banner-text">
                                Our commitment is to provide our clients with the highest level of service and solutions that drive success and growth. We are committed to excellence in all that we do, and we strive to exceed our clients' expectations at every turn.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div>
                                    <h3 className="content-subtitle text-align-center">
                                        Our Key Values
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="row service-inner-card-container">
                            {
                                KEY_VALUES_DATA?.map((ele, index) => {
                                    return (
                                        <div className="col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4" key={index}>
                                            <div className="service-inner-card-body flex justify-content-center align-items-center">
                                                <img className="card-line-vector" src={require("../../../assets/images/commitment/line-design-card.png")}
                                                    alt="line_design_for_card" />
                                                <img className="card-icon-vector" src={require("../../../assets/images/commitment/card-icon-vector.png")}
                                                    alt="card-icon-vector" />
                                                <span className="service-inner-card-title">{ele.cardTitle}</span>
                                                <p className="service-inner-card-content">
                                                    {ele.cardContent}
                                                </p>
                                                {ele.button}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div>
                                    <h4 className="content-subtitle text-align-center">
                                        Our Vision and Mission
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row service-inner-img-card-container">
                        <div className="vision-card-img-1-anim flex justify-content-center align-items-center col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="service-inner-img-card-body">
                                <LazyLoadImage
                                    className="commitment-image-card"
                                    src={require("../../../assets/images/commitment/vision.jpeg")}
                                    alt="card1"
                                    effect="blur"
                                    visibleByDefault={require("../../../assets/images/commitment/vision.jpeg")}
                                />
                                <div className="flex justify-content-center align-items-center">
                                    <div className="commitment"></div>
                                    <span className="commitment-span">Our Vision</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                            <div className="row">
                                <div>
                                    <p className="p-content-lm">
                                        We have started <b>SharepointEmpower Pvt. Ltd.</b> with an aspirational dream, the dream of developing and foreseeing the solutions that never were; we provide different applications and solutions that can integrate into one package to produce the excellent and most ample user friendly.
                                    </p>
                                </div>
                                <div
                                    className="col col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row"><i className="pi pi-check-square"></i></th>
                                                <td>To empower people around the world with our products and services create a positive impact on society. </td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><i className="pi pi-check-square"></i></th>
                                                <td>To be the most trusted and respected brand in our market, known for our integrity and excellence in everything we do. </td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><i className="pi pi-check-square"></i></th>
                                                <td>To continuously improve our products and services to exceed our customers' expectations and provide them with the best possible experience. </td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><i className="pi pi-check-square"></i></th>
                                                <td>To be a responsible and ethical company, committed to environmental sustainability and social responsibility. </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row service-inner-img-card-container">
                        <div className="col col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                            <div className="row">
                                <div className="light-mode-content">
                                    <p className="p-content-lm">
                                        To provide the highest quality and eminent professional software development & service worldwide. We set and follow standards to maintain the quality of our work and set the pace of the software, web development, and services. We believe that high-quality and cost-effective are complementary terms. We tend to work towards clients' satisfaction. We are not a low-cost center, but we can take pride in our effective costing and strive to deliver the best quality. Supreme Quality is our assurance, and we value your time.
                                    </p>
                                </div>

                                <div
                                    className="col col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row"><i className="pi pi-check-square"></i></th>
                                                <td>Our mission is to provide high-quality products and services that exceed our customers' expectations and create long-term value for all stakeholders. </td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><i className="pi pi-check-square"></i></th>
                                                <td>We are committed to delivering innovative solutions that address the most pressing challenges of our time, from climate change to social inequality. </td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><i className="pi pi-check-square"></i></th>
                                                <td>Our mission is to empower people and organizations with the tools and resources they need to achieve their goals and realize their full potential.</td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><i className="pi pi-check-square"></i></th>
                                                <td>We are dedicated to being a responsible and ethical company, with a focus on sustainability, transparency, and accountability. </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="vision-card-img-2-anim flex justify-content-center align-items-center col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="service-inner-img-card-body">
                                <LazyLoadImage
                                    className="commitment-image-card"
                                    src={require("../../../assets/images/commitment/mission.jpeg")}
                                    alt="card2"
                                    effect="blur"
                                    visibleByDefault={require("../../../assets/images/commitment/mission.jpeg")}
                                />
                                <div className="flex justify-content-center align-items-center overlay-card">
                                    <div className="commitment"></div>
                                    <span className="commitment-span">Our Mission</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OurCommitment;