import React, { useEffect, useState } from 'react';
import Snowfall from 'react-snowfall';
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import "/node_modules/primeflex/primeflex.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import MasterPage from './components/masterPage/MasterPage';
import ApiDataProvider from './context/ApiDataContext';

const App = () => {
  const [showSnowfall, setShowSnowfall] = useState(true);

  useEffect(() => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentDate = now.getDate();

    const startMonth = 11;
    const startDay = 23;
    const endMonth = 0;
    const endDay = 2;

    let isSnowfallActive = false;

    if ((currentMonth === startMonth && currentDate >= startDay) ||
      (currentMonth === endMonth && currentDate <= endDay)) {
      isSnowfallActive = true;
    }
    setShowSnowfall(isSnowfallActive);
  }, []);


  return (
    <>
      <div>
        <ApiDataProvider>
          <MasterPage />
        </ApiDataProvider>
        <div>
          {showSnowfall && (
            <Snowfall snowflakeCount={200} speed={[0.5, 1]} radius={[0.5, 1.5]} color='#BEDCFF' wind={[0, 0.5]} style={{
              position: 'fixed',
              zIndex: 999,
            }} />
          )}
        </div>
      </div>
    </>
  );
};

export default App;