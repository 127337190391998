import React, { useEffect, useContext, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { ApiDataContext } from '../../context/ApiDataContext';
import useWindowSize from '../commonComponents/commonFunction/UseWindowSize';
import ReactHelmetTag from '../commonComponents/reactHelmet/ReactHelmetTag';
import Megamenu from '../megamenu/Megamenu';
import './FAQ.css';

function FAQ({ project }) {
    const { faqData } = useContext(ApiDataContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [accordionClicked, setAccordionClicked] = useState(false);
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState('');
    const { windowWidth } = useWindowSize();

    const commonfaq = faqData?.filter(item => item.projectName === null);

    useEffect(() => {
        document.title = 'FAQs';
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        const extractLabels = (data) => {
            const labels = new Set();
            data?.forEach(item => {
                const faQtags = item.faQtags || '[]';
                try {
                    const tags = JSON.parse(faQtags);
                    tags.forEach(tag => {
                        labels.add(tag.Label);
                    });
                } catch (e) {
                    console.error('Error parsing faQtags:', e);
                }
            });
            return Array.from(labels);
        };

        const extractedLabels = extractLabels(faqData);
    }, [faqData]);

    useEffect(() => {

        const handleHashChange = () => {
            if (!accordionClicked) {
                const id = window.location.hash.replace('#', '');
                const formattedId = id.replace(/-/g, ' ');
                const formattedIdWithQuestionMark = formattedId.endsWith('?') ? formattedId : `${formattedId}?`;

                if (formattedIdWithQuestionMark) {
                    const faqItem = commonfaq.find(item => item.faqQuestion === formattedIdWithQuestionMark);
                    if (faqItem) {
                        const index = commonfaq.indexOf(faqItem);
                        setActiveIndex(index >= 0 ? index : null);

                        const faqElement = document.getElementById(`${formattedIdWithQuestionMark}`);

                        if (faqElement) {
                            const faqScrollElement = document.querySelector('.faq-scroll');
                            const headerHeight = document.querySelector('.faq-header').offsetHeight;
                            faqScrollElement.scrollTo({
                                top: faqElement.offsetTop - headerHeight,
                                behavior: 'smooth',
                            });
                        }
                    }
                }
            }
        };

        handleHashChange();
        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [commonfaq, accordionClicked]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const clearSearch = () => {
        setSearchQuery('');
    };

    const filterFAQs = (faqItems) => {
        return faqItems?.filter((faqItem) => {
            const matchesSearchQuery = faqItem.faqQuestion.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesLabel = selectedLabel === '' || (faqItem.faQtags && JSON.parse(faqItem.faQtags).some(tag => tag.Label === selectedLabel));
            return matchesSearchQuery && matchesLabel;
        });
    };

    const copyToClipboard = async (text, index) => {
        await setAccordionClicked(true);
        const modifiedText = text?.slice(0, -1);
        setActiveIndex(activeIndex === index ? index : null);
        navigator.clipboard.writeText(modifiedText);
        setShowCopyMessage(true);
        setTimeout(() => {
            setShowCopyMessage(false);
        }, 2000);
    };

    const createDynamicTabs = (faqItems) => {
        return faqItems.map((tab, i) => {
            const faqId = `${tab.faqQuestion}`;
            const faqUrl = `${window.location.origin}${window.location.pathname}#${faqId.replace(/\s/g, '-')}`;

            return (
                <AccordionTab
                    key={tab.faqQuestion}
                    id={accordionClicked === false && showCopyMessage === false ? faqId : ''}
                    className='cursor-pointer'
                    header={
                        <>
                            <div className='flex flex-column w-full'>
                                <div className="flex align-items-center justify-content-between w-full faq-mobile-fontsize text-base">
                                    <div className='flex'>
                                        <span className='ml-1'>{tab.faqQuestion}</span>
                                    </div>
                                    <div className='w-1 flex align-items-center justify-content-around'>
                                        <i className={`pi ${activeIndex === i ? 'pi-link' : ''}`} onClick={() => copyToClipboard(faqUrl, i)} />
                                        <i className={`pi ${activeIndex === i ? 'pi-angle-up' : 'pi-angle-down'}`} />
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </>
                    }

                    onClick={async () => {
                        await setAccordionClicked(true);
                        let newHash = `#${faqId.replace(/\s/g, '-')}`;
                        newHash = newHash.replace(/\?$/, '');
                        window.location.hash = newHash;
                    }}
                >
                    <div className="m-0 text-sm" style={{ overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: tab.faqAnswer }} />
                </AccordionTab>
            );
        });
    };

    const filteredFAQs = filterFAQs(faqData);

    return (
        <div>
            <Megamenu />
            <ReactHelmetTag link="https://www.sharepointempower.com/FAQ" />
            <div className='container-fluid'>
                <div className=' container faq-header fixed-top-faqs flex justify-content-between flex-wrap align-items-center py-2'>
                    <div className='flex align-items-center'>
                        <h1 style={{ fontSize: '26px', fontWeight: '600' }}>{windowWidth > 499 ? 'Frequently Asked Questions' : 'FAQs'}</h1>
                    </div>
                    <div>
                        <span className="p-input-icon-right w-auto ml-3">
                            <i className={`pi ${searchQuery ? 'pi-times' : 'pi-search'}`} onClick={searchQuery ? clearSearch : null} style={{ cursor: searchQuery ? 'pointer' : 'default' }} />
                            <InputText placeholder="Search" value={searchQuery} onChange={handleSearch} className="p-inputtext-sm w-auto" />
                        </span>
                    </div>
                </div>
                <div className="container-fluid faq-scroll">
                    {!project && (
                        <div className="container common-faq">
                            {faqData.length > 0 ? (
                                <>
                                    <div>
                                        {filteredFAQs.length > 0 ? (
                                            <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                                {createDynamicTabs(filteredFAQs)}
                                            </Accordion>) : (
                                            <p>No results found</p>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showCopyMessage && (
                <div className="copy-message">Link copied to clipboard successfully.</div>
            )}
        </div>
    );
};

export default FAQ;