import React from 'react';

const SharepointSitePermission = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 50 50" fill="gray" xmlns="http://www.w3.org/2000/svg">
            <path stroke="gray" d="M19.6043 41.6668H13.5418C10.4168 41.6668 7.66683 40.6252 5.43766 38.396C3.2085 36.2085 2.0835 33.521 2.0835 30.3752C2.0835 27.6668 2.896 25.2502 4.521 23.1252C6.106 21.048 8.3822 19.6069 10.9377 19.0627C11.8127 15.8752 13.5418 13.2918 16.146 11.3127C18.7502 9.37516 21.7085 8.3335 25.0002 8.3335C29.0627 8.3335 32.521 9.75016 35.3335 12.5835C38.1668 15.396 39.5835 18.8543 39.5835 22.9168C41.9793 23.1877 43.9793 24.2293 45.5418 26.0418C46.8752 27.5627 47.6252 29.2918 47.8335 31.2502H37.5002C36.5288 29.9576 35.2704 28.9085 33.8242 28.1854C32.3781 27.4623 30.7837 27.0851 29.1668 27.0835C23.3335 27.0835 18.7502 31.6668 18.7502 37.5002C18.7502 39.0002 19.0627 40.396 19.6043 41.6668ZM47.9168 35.4168V39.5835H43.7502V43.7502H39.5835V39.5835H35.0002C34.1668 42.0835 31.8752 43.7502 29.1668 43.7502C25.6252 43.7502 22.9168 41.0418 22.9168 37.5002C22.9168 33.9585 25.6252 31.2502 29.1668 31.2502C31.8752 31.2502 34.1668 32.9168 35.0002 35.4168H47.9168ZM31.2502 37.5002C31.2502 36.4585 30.4168 35.4168 29.1668 35.4168C27.9168 35.4168 27.0835 36.4585 27.0835 37.5002C27.0835 38.5418 27.9168 39.5835 29.1668 39.5835C30.4168 39.5835 31.2502 38.5418 31.2502 37.5002Z" fill="gray" />
        </svg>
    )
};

export default SharepointSitePermission;