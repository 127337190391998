import React from "react";
import ReactHelmetTag from "../../commonComponents/reactHelmet/ReactHelmetTag";
import AllProjects from "../../commonComponents/userGuide/Allprojects";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const SPEIntranetGuide = () => {
  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/guidelines/spe-intranet"
        metaKeywords="Our SPE Intranet management system also facilitates seamless collaboration among team members. It offers features like Document libraries, Sort & searching functionality, and system customization, allowing employees to work together efficiently."
      />

      <section>
        <LazyLoadComponent>
          <AllProjects project="SPE Intranet" path="/spe-intranet"/>
        </LazyLoadComponent>
      </section>
    </div>
  );
};

export default SPEIntranetGuide;
