import React from "react";
import ReactHelmetTag from "../../commonComponents/reactHelmet/ReactHelmetTag";
import Allprojects from "../../commonComponents/userGuide/Allprojects";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const HelpdeskGuide = () => {
  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/guidelines/helpdesk-system"
        metaKeywords="SharepointEmpower Pvt. Ltd. Helpdesk software application, also known as a helpdesk ticketing system or support ticket system, is a software tool designed to streamline and manage customer support interactions. It provides a centralized platform for businesses to receive, track, and resolve customer inquiries, requests, or technical issues efficiently."
      />
      <section>
        <LazyLoadComponent>
          <Allprojects project="Helpdesk" path="/helpdesk-system"></Allprojects>
        </LazyLoadComponent>
      </section>
    </div>
  );
};

export default HelpdeskGuide;
