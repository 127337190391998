import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactHelmetTag from '../commonComponents/reactHelmet/ReactHelmetTag';
import './LegalTermOfUse.css'
import Megamenu from '../megamenu/Megamenu';

function LegalTermOfUse() {

  useEffect(() => {
    document.title = "Legal Terms Of Use";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Megamenu />
      <div className='content-div'>
        <ReactHelmetTag
          link="https://www.sharepointempower.com/legal-terms-of-use"
        />
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div id="first-section-margin-top-for-navbar">
                  <div>
                    <h1 className="h1-tag-lm">Legal Terms Of Use</h1>
                  </div>
                </div>
                <div>
                  <h2 className="h2-subtitle-lm">Standard Contract </h2>
                </div>
                <div>
                  <p className="p-content-lm">
                    Updated January 2024
                    <br></br>
                    <br></br>
                    This Standard Contract (“Agreement”) is between you (“you” or “Customer”) and the
                    publisher
                    (“Publisher”) from which you are procuring Offerings (defined below) and governs your
                    use of Offerings
                    purchased through either Microsoft AppSource or Azure Marketplace (collectively,
                    “Marketplace”).
                    <br></br>
                    <br></br>
                    This Agreement is the parties’ entire agreement on this subject and merges and
                    supersedes all related
                    prior and contemporaneous agreements. By agreeing to these terms, you represent and
                    warrant that
                    you have the authority to accept this Agreement, and you also agree to be bound by its
                    terms. This
                    Agreement applies to all Orders entered into under this Agreement. Capitalized terms
                    have the
                    meanings given under “Definitions.”
                    <br></br>
                    <br></br>
                    <b>Microsoft has created this template Standard Contract to facilitate a transaction
                      between Publisher
                      and Customer. Both parties acknowledge that Microsoft is not a party to this
                      Agreement,
                      nor in
                      anyway responsible for the parties’ actions or obligations under this Agreement.
                      Microsoft’s
                      relationship with Customer and Publisher is solely governed by Microsoft’s
                      respective
                      agreements with
                      those parties; Microsoft otherwise disclaims all liability resulting from this
                      Agreement
                      (including any
                      Orders).</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne" aria-expanded="false"
                        aria-controls="flush-collapseOne">
                        <b>LICENSE TO OFFERINGS</b>
                      </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <ol>
                          <li><b>License grant:-</b> Offerings are licensed and not sold. Upon
                            acceptance of an Order, and subject to
                            Customer’s compliance with this Agreement, Publisher grants Customer a
                            nonexclusive and
                            limited license to use the ordered Offerings. These licenses are solely
                            for Customer’s own use
                            and business purposes and are nontransferable except as expressly
                            permitted under this
                            Agreement or applicable law.
                            <br></br>
                            Offerings may contain or be provided with components that are subject to
                            open-source
                            software licenses. Any use of those components may be subject to
                            additional terms and
                            conditions and Customer agrees that any applicable licenses governing
                            the use of the
                            components will be incorporated by reference in this Agreement.
                          </li>
                          <br></br>
                          <li><b>Duration of licenses:-</b> Licenses granted on a subscription basis
                            expire at the end of the applicable
                            subscription period set forth in the Order, unless renewed. Licenses
                            granted for metered
                            Offerings billed periodically based on usage continue as long as
                            Customer continues to pay for
                            its usage of the Offerings. All other licenses become perpetual upon
                            payment in full.
                          </li>
                          <br></br>
                          <li><b>End Users:-</b> Customer will control access to and use of the
                            Offerings by End Users and is
                            responsible for any use of the Offerings that does not comply with this
                            Agreement.
                          </li>
                          <br></br>
                          <li><b>Affiliates:-</b> Customer may order Offerings for use by its
                            Affiliates. If it does, the licenses granted
                            to Customer under this Agreement will apply to such Affiliates, but
                            Customer will have the sole
                            right to enforce this Agreement against Publisher. Customer will remain
                            responsible for all obligations under this Agreement and for its
                            Affiliates’ compliance with this Agreement and any
                            applicable Order(s).
                          </li>
                          <br></br>
                          <li><b>Reservation of Rights:-</b> Publisher reserves all rights not
                            expressly granted in this Agreement.
                            Offerings are protected by copyright and other intellectual property
                            laws and international
                            treaties. No rights will be granted or implied by waiver or estoppel.
                            Rights to access or use
                            Offerings on a device do not give Customer any right to implement
                            Publisher’s patents or other
                            intellectual property in the device itself or in any other software or
                            devices.
                          </li>
                          <br></br>
                          <li><b>Restrictions:-</b> Except as expressly permitted in this Agreement,
                            Documentation or an Order,
                            Customer must not (and is not licensed to):
                            <ol>
                              <li>Copy, modify, reverse engineer, decompile, or disassemble any
                                Offering, or attempt to
                                do so; </li>
                              <li>Install or use any third-party software or technology in any way
                                that would subject
                                Publisher’s intellectual property or technology to any other
                                license terms;</li>
                              <li>Work around any technical limitations in an Offering or
                                restrictions in Documentation;</li>
                              <li>Separate and run parts of an Offering on more than one device;
                              </li>
                              <li>Upgrade or downgrade parts of an Offering at different times;
                              </li>
                              <li>Use an Offering for any unlawful purpose; </li>
                              <li>Transfer parts of an Offering separately; or</li>
                              <li>Distribute, sublicense, rent, lease, or lend any Offerings, in
                                whole or in part, or use them
                                to offer hosting services to a third party. </li>
                            </ol>
                          </li>
                          <br></br>
                          <li><b>License transfers:-</b> Customer may only transfer fully-paid,
                            perpetual licenses to (1) An Affiliate or
                            (2) A third party solely in connection with the transfer of hardware to
                            which, or employees to
                            whom, the licenses have been assigned as part of (A) A divestiture of
                            all or part of an Affiliate or
                            (B) A merger involving Customer or an Affiliate. Upon such transfer,
                            Customer must uninstall and
                            discontinue using the licensed Offering and render any copies unusable.
                            Customer must notify
                            Publisher of a License transfer and provide the transferee a copy of
                            this Agreement and any
                            other documents necessary to show the scope, purpose, and limitations of
                            the licenses
                            transferred. Attempted license transfers that do not comply with this
                            section are void.
                          </li>
                          <br></br>
                          <li><b>Feedback:-</b> Any Feedback is given voluntarily, and the provider
                            grants to the recipient, without
                            charge, a non-exclusive license under provider’s owned or controlled
                            non-patent intellectual
                            property rights to make, use, modify, distribute, and commercialize the
                            Feedback as part of any
                            of recipient’s products and services, in whole or in part and without
                            regard to whether such
                            <br></br>
                            Feedback is marked or otherwise designated by the provider as
                            confidential. The provider
                            retains all other rights in any Feedback and limits the rights granted
                            under this section to
                            licenses under its owned or controlled non-patent intellectual property
                            rights in the Feedback
                            (which do not extend to any technologies that may be necessary to make
                            or use any product or
                            service that incorporates, but are not expressly part of, the Feedback,
                            such as enabling
                            technologies).
                          </li>
                        </ol>
                      </div >
                    </div >
                  </div >

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo" aria-expanded="false"
                        aria-controls="flush-collapseTwo">
                        <b>PRIVACY</b>
                      </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <ol>
                          <li><b>EU Standard Contractual Clauses:-</b> To the extent applicable, the
                            parties will abide by the
                            requirements of European Economic Area and Swiss data protection law
                            regarding the
                            collection, use, transfer, retention, and other processing of Personal
                            Data from the European
                            Economic Area and Switzerland. All transfers of Customer Data out of the
                            European Union,
                            European Economic Area, and Switzerland will be governed by the Standard
                            Contractual
                            Clauses, as designated by the European Commission, made available by the
                            Publisher at the
                            applicable URL for such terms or as otherwise communicated to Customer.
                          </li>
                          <br></br>
                          <li><b>Personal Data:-</b> Customer consents to the processing of Personal
                            Data by Publisher and its
                            Affiliates, and their respective agents and Subcontractors, as provided
                            in this Agreement. Before
                            providing Personal Data to Publisher, Customer will obtain all required
                            consents from third
                            parties (including Customer’s contacts, partners, distributors,
                            administrators, and employees)
                            under applicable privacy and Data Protection Laws.
                          </li>
                          <br></br>
                          <li><b>Processing of Personal Data; GDPR:- </b> To the extent Publisher is a
                            processor or subprocessor of
                            Personal Data subject to the GDPR, the Standard Contractual Clauses
                            govern that processing and
                            the parties also agree to the following terms in this subsection
                            (“Processing of Personal Data;
                            GDPR”):
                            <br></br>
                            <ol>
                              <li><b>Processor and Controller Roles and Responsibilities:- </b>
                                Customer and Publisher agree that
                                Customer is the controller of Personal Data and Publisher is the
                                processor of such data,
                                except when (a) Customer acts as a processor of Personal Data,
                                in which case Publisher
                                is a subprocessor or (b) stated otherwise in any
                                Offering-specific terms. Publisher will
                                process Personal Data only on documented instructions from
                                Customer. In any instance
                                where the GDPR applies and Customer is a processor, Customer
                                warrants to Publisher
                                that Customer’s instructions, including appointment of Processor
                                as a processor or
                                subprocessor, have been authorized by the relevant controller.
                              </li>
                              <br></br>
                              <li><b>Processing Details:-</b> The parties acknowledge and agree
                                that:
                                <ol>
                                  <li>The subject-matter of the processing is limited to
                                    Personal Data within the scope
                                    of the GDPR;</li>
                                  <li>The duration of the processing will be for the duration
                                    of the Customer’s right to
                                    use the Offering and until all Personal Data is deleted
                                    or returned in accordance
                                    with Customer instructions or the terms of this
                                    Agreement;</li>
                                  <li>The nature and purpose of the processing will be to
                                    provide the Offering
                                    pursuant to this Agreement;</li>
                                  <li>The types of Personal Data processed by the Offering
                                    include those expressly
                                    identified in Article 4 of the GDPR; and </li>
                                  <li>The categories of data subjects are Customer’s
                                    representatives and end users,
                                    such as employees, contractors, collaborators, and
                                    customers, and other data
                                    subjects whose Personal Data is contained within any
                                    data made available to
                                    Publisher by Customer.</li>
                                </ol>
                              </li>
                              <br></br>
                              <li><b>Data Subject Rights; Assistance with Requests:-</b> Publisher
                                will make information
                                available to Customer in a manner consistent with the
                                functionality of the Offering and
                                Publisher’s role as a processor of Personal Data of data
                                subjects and the ability to fulfill
                                data subject requests to exercise their rights under the GDPR.
                                Publisher will comply with
                                reasonable requests by Customer to assist with Customer’s
                                response to such a data
                                subject request. If Publisher receives a request from Customer’s
                                data subject to exercise
                                one or more of its rights under the GDPR in connection with an
                                Offering for which
                                Publisher is a data processor or subprocessor, Publisher will
                                redirect the data subject to
                                make its request directly to Customer. Customer will be
                                responsible for responding to
                                any such request including, where necessary, by using the
                                functionality of the Offering.
                                Publisher will comply with reasonable requests by Customer to
                                assist with Customer’s
                                response to such a data subject request.
                              </li>
                              <br></br>
                              <li><b>Use of Subprocessors:-</b> Customer consents to Publisher
                                using the subprocessors listed at
                                the applicable Publisher URL or as otherwise communicated to
                                Customer. Publisher
                                remains responsible for its subprocessors’ compliance with the
                                obligations herein.
                                Publisher may update its list of subprocessors from time to
                                time, by providing Customer
                                at least 14 days notice before providing any new subprocessor
                                with access to Personal
                                Data. If Customer does not approve of any such changes, Customer
                                may terminate any
                                subscription for the affected Offering without penalty by
                                providing, prior to expiration
                                of the notice period, written notice of termination that
                                includes an explanation of the
                                grounds for non-approval.
                              </li>
                              <br></br>
                              <li><b>Records of Processing Activities:-</b> Publisher will maintain
                                all records required by Article
                                30(2) of the GDPR and, to the extent applicable to the
                                processing of Personal Data on
                                behalf of Customer, make them available to Customer upon
                                request.
                              </li>
                            </ol>
                          </li>
                          <br></br>
                          <li><b>Security:-</b> Publisher will take appropriate security measures that
                            are required by Data Protection
                            Laws and in accordance with good industry practice relating to data
                            security.
                          </li>
                          <br></br>
                          <li><b>Support Data:-</b> Publisher may collect and use Support Data
                            internally to provide technical
                            support for the Offering. Publisher will not use Support Data for any
                            other purpose unless
                            otherwise agreed in writing by the parties.
                          </li>
                        </ol>
                      </div >
                    </div >
                  </div >

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree" aria-expanded="false"
                        aria-controls="flush-collapseThree">
                        <b>CONFIDENTIALITY</b>
                      </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <ol>
                          <li><b>Non-Disclosure Agreement:-</b> The parties will treat all confidential
                            information exchanged
                            between the parties under this Agreement in accordance with the separate
                            nondisclosure
                            agreement (“NDA”) executed by the parties. If no separate NDA is in
                            effect, the following
                            provisions apply to the parties’ exchange of confidential information.
                          </li>
                          <br></br>
                          <li><b>Confidential Information:-</b> “Confidential Information” is non-public
                            information that is
                            designated “confidential” or that a reasonable person should understand
                            is confidential,
                            including, but not limited to, Customer Data, Support Data, the terms of
                            this Agreement, and
                            Customer’s account authentication credentials. Confidential Information
                            does not include
                            information that: (1) becomes publicly available without a breach of a
                            confidentiality obligation;
                            (2) the receiving party received lawfully from another source without a
                            confidentiality
                            obligation; (3) is independently developed; or (4) is a comment or
                            suggestion volunteered about
                            the other party’s business, products, or services.
                          </li>
                          <br></br>
                          <li><b>Protection of Confidential Information:-</b> Each party will take
                            reasonable steps to protect the
                            other’s Confidential Information and will use the other party’s
                            Confidential Information only for
                            purposes of the parties’ business relationship. Neither party will
                            disclose Confidential
                            Information to third parties, except to its Representatives, and then
                            only on a need-to-know
                            basis under nondisclosure obligations at least as protective as this
                            Agreement. Each party
                            remains responsible for the use of Confidential Information by its
                            Representatives and, in the
                            event of discovery of any unauthorized use or disclosure, must promptly
                            notify the other party.
                          </li>
                          <br></br>
                          <li><b>Disclosure required by law:-</b> A party may disclose the other’s
                            Confidential Information if required
                            by law, but only after it notifies the other party (if legally
                            permissible) to enable the other party
                            to seek a protective order.
                          </li>
                          <br></br>
                          <li><b>Duration of Confidentiality obligation:-</b> These obligations apply:
                            (1) for Customer Data, until it is
                            deleted by Publisher; and (2) for all other Confidential Information,
                            for a period of five years
                            after a party receives the Confidential Information.
                          </li>
                          <br></br>
                        </ol>
                      </div >
                    </div >
                  </div >

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour" aria-expanded="false"
                        aria-controls="flush-collapseFour">
                        <b>SERVICE LEVEL AGREEMENTS (SLA)</b>
                      </button>
                    </h2>
                    <div id="flush-collapseFour" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        Publisher may offer further availability and support obligations for an
                        Offering. Such service
                        level agreement (“SLA”) will be made available by the Publisher at the
                        applicable URL for such
                        SLA or as otherwise communicated to Customer.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive" aria-expanded="false"
                        aria-controls="flush-collapseFive">
                        <b>VERIFYING COMPLIANCE</b>
                      </button>
                    </h2>
                    <div id="flush-collapseFive" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <ol>
                          <li>Customer must keep records relating to Offerings it and its Affiliates
                            use or distribution. At
                            Publisher’s expense, Publisher may verify Customer’s and its Affiliates’
                            compliance with this
                            Agreement by directing an independent auditor (under nondisclosure
                            obligations) to conduct an
                            audit or ask Customer to complete a self-audit process. Customer must
                            promptly provide any
                            information and documents that Publisher or the auditor reasonably
                            requests related to the
                            verification and access to systems running the Offerings. If
                            verification or self-audit reveals any
                            unlicensed use, Customer must order sufficient licenses to cover the
                            period of its unlicensed
                            use. The audits may be conducted more frequently, if required by the
                            party’s auditors and/or
                            regulators, of books and records related to this Agreement. The expenses
                            for all such audit will
                            be borne by the party conducting the audit. All information and reports
                            related to the
                            verification process will be Confidential Information and used solely to
                            verify compliance.
                          </li>
                          <br></br>
                          <li>Upon request, Publisher will make available to Customer all information
                            necessary to conduct
                            an audit and demonstrate compliance under GDPR provisions for the
                            processing of Personal
                            Data. Customer may request information through a security questionnaire
                            or self-attestation.F
                          </li>
                        </ol>
                      </div>
                    </div >
                  </div >

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix" aria-expanded="false"
                        aria-controls="flush-collapseSix">
                        <b>REPRESENTATION AND WARRANTIES</b>
                      </button>
                    </h2>
                    <div id="flush-collapseSix" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <ol>
                          <li>Publisher continuously represents and warrants that:
                            <br></br>
                            <ol>
                              <li>It has full rights and authority to enter into, perform under,
                                and grant the rights in, this
                                Agreement;
                              </li>
                              <br></br>
                              <li>Its performance will not violate any agreement or obligation
                                between it and any third
                                party;
                              </li>
                              <br></br>
                              <li>The Offering will substantially conform to the Documentation;
                              </li>
                              <br></br>
                              <li><b>The Offering will not:-</b>
                                <ol>
                                  <li>To the best of Publisher’s knowledge, infringe or
                                    violate any third party patent,
                                    copyright, trademark, trade secret, or other proprietary
                                    right; or</li>
                                  <li>Contain viruses or other malicious code that will
                                    degrade or infect any products,
                                    services, software, or Customer’s network or systems,
                                    and</li>
                                </ol>
                              </li>
                              <br></br>
                              <li>While performing under this Agreement, Publisher will comply
                                with law, including Data
                                Protection Laws and Anti-Corruption Laws, and will provide
                                training to its employees
                                regarding Anti-Corruption Laws.
                              </li>
                            </ol>
                          </li>
                          <br></br>
                          <li><b>Disclaimer:-</b> Except as expressly stated in this Agreement, the
                            Offering is provided as is. To the
                            maximum extent permitted by law, Publisher disclaims any and all other
                            warranties (express,
                            implied or statutory, or otherwise) including of merchantability or
                            fitness for a particular purpose, whether arising by a course of
                            dealing, usage or trade practice, or course of
                            performance.
                          </li>
                        </ol>
                      </div >
                    </div >
                  </div >

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSeven">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSeven" aria-expanded="false"
                        aria-controls="flush-collapseSeven">
                        <b>DEFENSE OF THIRD-PARTY CLAIMS</b>
                      </button>
                    </h2>
                    <div id="flush-collapseSeven" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <ol>
                          <li><b>By Customer:-</b> Customer will defend Publisher and its Affiliates
                            from
                            and against any and all third
                            party claims, actions, suits, proceedings arising from or related to:
                            Customer’s or any authorized
                            user’s violation of this Agreement or user terms (a “Claims Against
                            Publisher”), and will
                            indemnify Publisher and its Affiliates for all reasonable attorney’s
                            fees
                            incurred and damages
                            and other costs finally awarded against Publisher or its Affiliates in
                            connection with or as a result
                            of, and for amounts paid by Publisher or its Affiliates under a
                            settlement
                            Customer approves of
                            in connection with a Claim Against Publisher. Publisher must provide
                            Customer with prompt
                            written notice of any Claims Against Publishers and allow Customer the
                            right
                            to assume the
                            exclusive defense and control of the claim and cooperate with any
                            reasonable
                            requests assisting
                            Customer’s defense and settlement of such matter.
                          </li>
                          <br></br>
                          <li><b>By Publisher:-</b> Publisher will defend Customer from and against any
                            and
                            all third party claims,
                            actions, suits, proceedings, and demands alleging that: (i) the use of
                            the
                            Offering as permitted
                            under the Contract infringes or misappropriates a third party’s
                            intellectual
                            property rights and
                            (ii) any violation of applicable law including Data Protection Laws (a
                            “Claim Against Customer”),
                            and will indemnify Customer for all reasonable attorney’s fees incurred
                            and
                            damages and other
                            costs finally awarded against Customer in connection with or as a result
                            of,
                            and for amounts
                            paid by Customer under a settlement Publisher approve of in connection
                            with
                            a Claim Against
                            Customer; provided, however, that the Publisher has no liability if a
                            Claim
                            Against Customer
                            arises from: (1) Customer Data or non-Publisher products, including
                            third-party software; and
                            (2) any modification, combination or development of the Offering that is
                            not
                            performed or
                            authorized in writing by Publisher, including in the use of any
                            application
                            programming interface
                            (API). Customer must provide Publisher with prompt written notice of any
                            Claim Against
                            Customer and allow Publisher the right to assume the exclusive defense
                            and
                            control and
                            cooperate with any reasonable requests assisting Publisher’s defense and
                            settlement of such
                            matter. This section states Publisher sole liability with respect to,
                            and
                            Customer’s exclusive
                            remedy against Publisher for, any Claim Against Customer.
                          </li>
                          <br></br>
                          <li>Not with standing anything contained in the above subsections (a) and (b),
                            (1)
                            an indemnified
                            party will always be free to choose its own counsel if it pays for the
                            cost
                            of such counsel; and (2)
                            no settlement may be entered into by an indemnifying party, without the
                            express written
                            consent of the indemnified parties (such consent not to be unreasonably
                            withheld), if: (A) the
                            third party asserting the claim is a government agency; (B) the
                            settlement
                            arguably involves the
                            making of admissions by the indemnified parties; (C) the settlement does
                            not
                            include a full
                            release of liability for the indemnified parties; or (D) the settlement
                            includes terms other than a
                            full release of liability for the indemnified parties and the payment of
                            money.
                          </li>
                        </ol>
                      </div>
                    </div >
                  </div >

                  {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingEight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight" aria-expanded="false"
                      aria-controls="flush-collapseEight">
                      <b>LIMITATION OF LIABILITY</b>
                    </button>
                  </h2>
                  <div id="flush-collapseEight" className="accordion-collapse collapse"
                    aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      For each Offering, each party’s maximum, aggregate liability to the other under
                      this Agreement
                      is limited to direct damages finally awarded in an amount not to exceed the
                      amounts Customer
                      was required to pay for the Offerings during the term of the applicable
                      licenses, subject to the
                      following:
                      <br></br>
                      <ol>
                        <li><b>Subscriptions:-</b> For Offerings ordered on a subscription basis,
                          Publisher’s maximum
                          liability to Customer for any incident giving rise to a claim will not
                          exceed the amount
                          Customer paid for the Offering during the 12 months before the incident
                          or $500,000,
                          whichever is greater.
                          <br></br>
                          For Offerings ordered on a subscription basis, Publisher’s maximum
                          liability to Customer
                          for any unauthorized access, use, or disclosure of Customer Data due to
                          a breach of
                          Publisher’s obligations under Section II(6) (Security), Publisher’s
                          maximum liability to
                          Customer will not exceed two times (2x) the amount Customer paid for the
                          Offering
                          during the 12 month before the incident or $2,000,000, whichever is
                          greater.
                        </li>
                        <br></br>
                        <li><b>Free Offerings and distributable code:-</b> For Offerings provided free
                          of charge and code
                          that Customer is authorized to redistribute to third parties without
                          separate payment to
                          Publisher, Publisher’s liability is limited to direct damages finally
                          awarded up to
                          US$5,000
                        </li>
                        <br></br>
                        <li><b>No Indirect Damages:-</b> In no event will either party be liable for
                          indirect, incidental,
                          special, punitive, or consequential damages, or loss of use, loss of
                          profits, or
                          interruption of business, however caused or on any theory of liability.
                        </li>
                        <br></br>
                        <li><b>Exceptions:-</b> No limitation or exclusions will apply to liability
                          arising out of either party’s:
                          (1) confidentiality obligations under Section 3 (except for liability
                          related to Customer
                          Data, which will remain subject to the limitations and exclusions
                          above); (2) defense
                          obligation under Section 7; (3) violation of the other party’s
                          intellectual property rights;
                          or (4) gross negligence, willful misconduct, or fraud.
                        </li>
                      </ol>
                    </div>
                  </div >
                </div > */}

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingNine">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseNine" aria-expanded="false"
                        aria-controls="flush-collapseNine">
                        <b>PRICING AND PAYMENT</b>
                      </button>
                    </h2>
                    <div id="flush-collapseNine" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        By accessing this website or purchasing our products, you agree to be bound by the following <Link to="/legal-terms-of-use"><b><u> terms and conditions</u></b></Link>. Please refer to the <Link to="/product-pricing-list"><b><u>Product Pricing</u></b></Link> and product subscription.
                        <br /><br />
                        Below are available payment options to purchase product licenses:
                        <br /><br />
                        <ol>
                          <li><b>Wire Transfer:-</b> Clients can choose to pay via wire transfer in USD. Please note that clients are responsible for any associated bank wire fees.
                          </li>
                          <br></br>
                          <li><b>Credit Cards:-</b> Clients may also opt to pay using Credit Cards through a direct payment link. Please note that applicable fees may be incurred based on the chosen payment platform or credit card provider, and these fees are the responsibility of the client.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTen" aria-expanded="false"
                        aria-controls="flush-collapseTen">
                        <b>TERM AND TERMINATION</b>
                      </button>
                    </h2>
                    <div id="flush-collapseTen" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <ol>
                          <li><b>Term:-</b> This Agreement is effective until terminated by a party, as
                            described below. The term for
                            each Order will be set forth therein.
                          </li>
                          <br></br>
                          <li><b>Termination without cause:-</b> Unless otherwise set forth in an Order,
                            either party may terminate
                            this Agreement or any Order without cause on 7 days’ notice.
                            Termination without cause will
                            not affect Customer’s perpetual licenses, and licenses granted on a
                            subscription basis will continue for the duration of the subscription
                            period(s), subject to the terms of this Agreement.
                            Publisher will not provide refunds or credits for any partial
                            subscription period(s) if the
                            Agreement or an Order is terminated without cause.
                          </li>
                          <br></br>
                          <li><b>No Indirect Damages:-</b> In no event will either party be liable for
                            indirect, incidental,
                            special, punitive, or consequential damages, or loss of use, loss of
                            profits, or
                            interruption of business, however caused or on any theory of liability.
                          </li>
                          <br></br>
                          <li><b>Termination for cause:-</b> Without limiting other remedies it may
                            have, either party may terminate
                            this Agreement or any Order immediately on notice if (i) the other party
                            materially breaches the
                            Agreement or an Order, and fails to cure the breach within 30 days after
                            receipt of notice of the
                            breach; or (ii) the other party becomes Insolvent. Upon such
                            termination, the following will
                            apply:
                            <br></br>
                            <ol>
                              <li>All licenses granted under this Agreement will terminate
                                immediately except for fullypaid, perpetual licenses.</li>
                              <li>All amounts due under any unpaid invoices will become due and
                                payable immediately.
                                For metered Offerings billed periodically based on usage,
                                Customer must immediately
                                pay for unpaid usage as of the termination date.</li>
                              <li>If Publisher is in breach, Customer will receive a credit for
                                any subscription fees,
                                including amounts paid in advance for unused consumption for any
                                usage period after
                                the termination date.</li>
                            </ol>
                          </li>
                          <br></br>
                          <li><b>Suspension:-</b> Publisher may suspend use of the Offering without
                            terminating this Agreement
                            during any period of material breach. Publisher will give Customer
                            reasonable notice before
                            suspending the Offering. Suspension will only be to the extent
                            reasonably necessary.
                          </li>
                          <br></br>
                          <li><b>Survival:-</b> The terms of this Agreement, including the applicable
                            Order, that are likely to require
                            performance, or have application to events that may occur, after the
                            termination or expiration
                            of this Agreement or any Order, will survive termination or expiration,
                            including all indemnity
                            obligations and procedures.
                          </li>
                        </ol>
                      </div >
                    </div >
                  </div >

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingEleven">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseEleven" aria-expanded="false"
                        aria-controls="flush-collapseEleven">
                        <b>MISCELLANEOUS</b>
                      </button>
                    </h2>
                    <div id="flush-collapseEleven" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <ol>
                          <li><b>Entire Agreement:-</b> This Agreement supersedes all prior and
                            contemporaneous communications,
                            whether written or oral, regarding the subject matter covered in this
                            Agreement. If there is a
                            conflict between any parts of this Agreement, the following order of
                            precedence will apply:
                            <br></br>
                            <ol>
                              <li>Order</li>
                              <li>This Agreement</li>
                              <li>Service Level Agreement (SLA) and </li>
                              <li>Documentation</li>
                            </ol>
                          </li>
                          <br></br>
                          <li><b>Independent contractors:-</b> The parties are independent contractors.
                            Customer and Publisher
                            each may develop products independently without using the other’s
                            Confidential Information.
                          </li>
                          <br></br>
                          <li><b>Agreement not exclusive:-</b> Customer is free to enter into
                            agreements to license, use, and
                            promote the services of others.
                          </li>
                          <br></br>
                          <li><b>Amendments:-</b> Unless otherwise agreed in a writing signed by both
                            parties, Publisher will not
                            change the terms of this Agreement, including privacy terms, during the
                            term of this Agreement.
                          </li>
                          <br></br>
                          <li><b>Assignment:-</b> Either party may assign this Agreement to an
                            Affiliate, but it must notify the other
                            party in writing of the assignment. Customer consents to the assignment
                            to an Affiliate or third
                            party, without prior notice, of any rights Publisher may have under this
                            Agreement to receive
                            payment and enforce Customer's payment obligations, and all assignees
                            may further assign such
                            rights without further consent. Furthermore, either party may assign
                            this Agreement without
                            the consent of the other party in connection with a merger,
                            reorganization, acquisition, or other
                            transfer of all or substantially all of such party’s assets. Any other
                            proposed assignment of this
                            Agreement must be approved by the non-assigning party in writing.
                            Assignment will not relieve
                            the assigning party of its obligations under the assigned Agreement. Any
                            attempted assignment
                            without required approval will be void.
                          </li>
                          <br></br>
                          <li><b>Severability:-</b> If any part of this Agreement is held to be
                            unenforceable, the rest of the
                            Agreement will remain in full force and effect.
                          </li>
                          <br></br>
                          <li><b>No third-party beneficiaries:-</b> This Agreement does not create any
                            third-party beneficiary rights
                            except as expressly provided by its terms.
                          </li>
                          <br></br>
                          <li><b>Notices:-</b> Notices must be in writing and will be treated as
                            delivered on the date received at the
                            address, date shown on the return receipt, email transmission date, or
                            date on the courier or fax
                            confirmation of delivery. Notices to Publisher must be sent to the
                            address stated in the Order.
                            Notices to Customer will be sent to the individual at the address
                            Customer identifies on its
                            account as its contact for notices. Publisher may send notices and other
                            information to
                            Customer by email or other electronic form.
                          </li>
                          <br></br>
                          <li><b>Applicable law:-</b>
                            <br></br>
                            <ol>
                              <li><b>United States and Canada:-</b> If you acquired the Offering
                                in the United States or Canada,
                                the laws of the state or province where you live (or, if a
                                business, where your principal
                                place of business is located) govern the interpretation of these
                                terms, claims for breach of them, and all other claims
                                (including consumer protection, unfair competition, and
                                tort claims), regardless of conflict of law principles. </li>
                              <li><b>Outside the United States and Canada:-</b> If you acquired
                                the Offering in any other
                                country, the laws of that country apply
                              </li>
                            </ol>
                          </li>
                          <br></br>
                          <li><b>Order of precedence:-</b> The body of this Agreement will take
                            precedence over any conflicting
                            terms in other documents that are part of this Agreement that are not
                            expressly resolved in
                            those documents. Terms in an amendment control over the amended document
                            and any prior
                            amendments concerning the same subject matter
                          </li>
                          <br></br>
                          <li><b>Government procurement rules:-</b> By accepting this Agreement,
                            Customer represents and
                            warrants that: (1) it has complied and will comply with all applicable
                            government procurement
                            laws and regulations; (2) it is authorized to enter into this Agreement;
                            and (3) this Agreement
                            satisfies all applicable procurement requirements
                          </li>
                          <br></br>
                          <li><b>Compliance with laws:-</b> Publisher will comply with all laws and
                            regulations applicable to its
                            provision of the Offerings. Publisher will obtain and maintain any
                            approvals, licenses, filings, or
                            registrations necessary to its performance, and will comply with all law
                            (including law related to
                            export, corruption, money laundering, or any combination of these).
                            Customer must also
                            comply with laws applicable to their use of the Offerings
                          </li>
                          <br></br>
                          <li><b>Construction:-</b> Neither party has entered this Agreement in
                            reliance on anything not contained
                            or incorporated in it. This Agreement is in English only. Any
                            translation of this Agreement into
                            another language is for reference only and without legal effect. If a
                            court of competent
                            jurisdiction finds any term of the Agreement unenforceable, the
                            Agreement will be deemed
                            modified as necessary to make it enforceable, and the rest of the
                            Agreement will be fully
                            enforced to affect the parties’ intent. Lists of examples following
                            “including”, “e.g.”, “for
                            example”, or the like are interpreted to include “without limitation,”
                            unless qualified by words
                            such as “only” or “solely.” This Agreement will be interpreted according
                            to its plain meaning
                            without presuming that it should favor either party. Unless stated or
                            context requires otherwise:
                            <br></br>
                            <ol>
                              <li>All internal references are to this Agreement and its parties;
                              </li>
                              <li>All monetary amounts are expressed and, if applicable, payable,
                                in U.S. dollars;</li>
                              <li>URLs are understood to also refer to successors, localizations,
                                and information or
                                resources linked from within websites at those URLs; </li>
                              <li>A party’s choices under this Agreement are in its sole
                                discretion, subject to any implied
                                duty of good faith; </li>
                              <li>“Written” or “in writing” means a paper document only, except
                                where email is expressly
                                authorized;</li>
                              <li>“Days” means calendar days;</li>
                              <li>“May” means that the applicable party has a right, but not a
                                concomitant duty,</li>
                              <li>“Partner,” if used in this Agreement or related documents, is
                                used in its common,
                                marketing sense and does not imply a partnership; </li>
                              <li>“Current” or “currently” means “as of the Effective Date” but
                                “then-current” means the
                                present time when the applicable right is exercised or
                                performance rendered or
                                measured; </li>
                              <li>“Notify” means to give notice under subsection (i) above; and
                              </li>
                              <li>A writing is “signed” when it has been hand-signed (i.e., with a
                                pen) or signed via an
                                electronic signature service by a duly authorized representative
                                of the signing party.</li>
                            </ol>
                          </li>
                        </ol>
                      </div >
                    </div >
                  </div >

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwelve">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwelve" aria-expanded="false"
                        aria-controls="flush-collapseTwelve">
                        <b>DEFINITIONS</b>
                      </button>
                    </h2>
                    <div id="flush-collapseTwelve" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        “Affiliate” means any legal entity that controls, is controlled by, or is under
                        common control
                        with a party.
                        <br></br>
                        <br></br>
                        “Anti-Corruption Laws” means all laws against fraud, bribery, corruption,
                        inaccurate books and
                        records, inadequate internal controls, money-laundering, and illegal software,
                        including the U.S.
                        Foreign Corrupt Practices Act.
                        <br></br>
                        <br></br>
                        “Control” means ownership of more than a 50% interest of voting securities in an
                        entity or the
                        power to direct the management and policies of an entity.
                        <br></br>
                        <br></br>
                        “Confidential Information” is defined in the “Confidentiality” section.
                        <br></br>
                        <br></br>
                        “Customer Data” means all data, including all text, sound, software, image or
                        video files that are
                        provided to Publisher or its Affiliates by, or on behalf of, Customer and its
                        Affiliates through use
                        of the Offering. Customer Data does not include Support Data.
                        <br></br>
                        <br></br>
                        “Data Protection Law” means any law applicable to Publisher or Customer,
                        relating to data
                        security, data protection and/or privacy, including Regulation (EU) 2016/679 of
                        the European
                        Parliament and of the Council of 27 April 2016 on the protection of natural
                        persons with regard
                        to processing of personal data and the free movement of that data (“GDPR”), and
                        any
                        implementing, derivative or related legislation, rule, regulation, and
                        regulatory guidance, as
                        amended, extended, repealed and replaced, or re-enacted.
                        <br></br>
                        <br></br>
                        “Documentation” means all user manuals, handbooks, training material,
                        requirements, and
                        other written or electronic materials Publisher makes available for, or that
                        result from use of,
                        the Offering.
                        <br></br>
                        <br></br>
                        “End User” means any person Customer permits to use an Offering or access
                        Customer Data.
                        <br></br>
                        <br></br>
                        “Feedback” means ideas, suggestions, comments, input, or know-how, in any form,
                        that one
                        party provides to the other in relation to recipient’s Confidential Information,
                        products, or
                        services. Feedback does not include sales forecasts, future release schedules,
                        marketing plans,
                        financial results, and high-level plans (e.g., feature lists) for future
                        products.
                        <br></br>
                        <br></br>
                        “Insolvent” means admitting in writing the inability to pay debts as they
                        mature; making a
                        general assignment for the benefit of creditors; suffering or permitting the
                        appointment of a
                        trustee or receiver for all or any of its (i.e., the non-terminating party’s)
                        assets, unless such
                        appointment is vacated or dismissed within 60 days from the date of appointment;
                        filing (or
                        having filed) any petition as a debtor under any provision of law relating to
                        insolvency, unless
                        such petition and all related proceedings are dismissed within 60 days of such
                        filing; being
                        adjudicated insolvent or bankrupt; having wound up or liquidated; or ceasing to
                        carry on
                        business.
                        <br></br>
                        <br></br>
                        “Offering” means all services, websites (including hosting), solutions,
                        platforms, and products
                        identified in an Order and that Publisher makes available under or in relation
                        to this Agreement,
                        including the software, equipment, technology, and services necessary for
                        Publisher to provide
                        the foregoing. Offering availability may vary by region.
                        <br></br>
                        <br></br>
                        “Order” means an ordering document used to transact the Offering via the
                        Marketplace.
                        <br></br>
                        <br></br>
                        “Personal Data” means any information relating to an identified or identifiable
                        natural person.
                        <br></br>
                        <br></br>
                        “Representatives” means a party’s employees, Affiliates, contractors, advisors
                        and consultants.
                        <br></br>
                        <br></br>
                        “Standard Contractual Clauses” means the standard data protection clauses for
                        the transfer of
                        personal data to processors established in third countries which do not ensure
                        an adequate
                        level of data protection, as described in Article 46 of the GDPR.
                        <br></br>
                        <br></br>
                        “Subcontractor” means any third party: (1) to whom Publisher delegates its
                        obligations under
                        this Agreement, including a Publisher Affiliate not contracting directly with
                        Customer through an
                        Order; or (2) who, in performing under a contract between it and Publisher or a
                        Publisher
                        Affiliate, stores, collects, transfers or otherwise processes Personal Data
                        (obtained or accessed
                        in connection with performing under this Agreement) or other Customer
                        Confidential
                        Information.
                        <br></br>
                        <br></br>
                        “Support Data” means all data, including all text, sound, video, image files, or
                        software, that are
                        provided to Publisher by or on behalf of Customer (or that Customer authorizes
                        Publisher to
                        obtain from an Offering) through an engagement with Publisher to obtain
                        technical support for
                        the Offering covered under this Agreement.
                        <br></br>
                        <br></br>
                        “Use” means to copy, download, install, run, access, display, use or otherwise
                        interact with
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThirteen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThirteen" aria-expanded="false"
                        aria-controls="flush-collapseThirteen">
                        <b>REFUND POLICY FOR PRODUCT LICENSE-PURCHASED</b>
                      </button>
                    </h2>
                    <div id="flush-collapseThirteen" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        Please be aware that our products consist of non-tangible, irrevocable digital goods, and therefore, we do not provide refunds once a purchase has been completed. It is your responsibility to fully comprehend this policy before acquiring the Software. We cannot guarantee or promise any refunds. Once a product license has been issued, there is no possibility for us to retrieve or revoke a digital item that has already been provided to a customer.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default LegalTermOfUse;