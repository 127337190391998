import React from "react";
import ReactHelmetTag from "../../commonComponents/reactHelmet/ReactHelmetTag";
import Allprojects from "../../commonComponents/userGuide/Allprojects";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function CMSGuide() {
  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/guidelines/construction-management-system"
        metaKeywords="Construction management system guidelines, Construction management system installation steps, Construction management system help center, Sharepoint Construction management system, CMS"
      />

      <section>
        <LazyLoadComponent>
          <Allprojects project="Construction Management System" path="/construction-management-system" ></Allprojects>
        </LazyLoadComponent>
      </section>
    </div>
  );
};

export default CMSGuide;
