import Carousel from "react-multi-carousel";
import { Image } from 'primereact/image';
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        paritialVisibilityGutter: 60
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        paritialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        paritialVisibilityGutter: 30
    }
};

const images = [
    require("../../assets/images/careerSharepointempower/company-culture-one.jpeg"),
    require("../../assets/images/careerSharepointempower/company-culture-two.jpeg"),
    require("../../assets/images/careerSharepointempower/company-culture-three.jpeg"),
    require("../../assets/images/careerSharepointempower/company-culture-four.jpeg"),
    require("../../assets/images/careerSharepointempower/company-culture-five.jpeg"),
    require("../../assets/images/careerSharepointempower/company-culture-six.jpeg"),
    require("../../assets/images/careerSharepointempower/company-culture-seven.jpeg"),
    require("../../assets/images/careerSharepointempower/company-culture-eight.jpeg"),
    require("../../assets/images/careerSharepointempower/company-culture-nine.jpeg"),
];

const CompanyCarousel = ({ deviceType }) => {
    return (
        <div className="container">
            <Carousel
                deviceType={deviceType}
                itemClass="image-item"
                responsive={responsive}
                autoPlay
                draggable={false}
                infinite
            >
                {images.slice(0, 12)?.map((image, index) => {
                    return (
                        <Image
                            key={index}
                            draggable={true}
                            width="380"
                            alt="company culture"
                            src={image}
                        />
                    );
                })}
            </Carousel>
        </div>
    );
};

export default CompanyCarousel;
